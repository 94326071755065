import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import StyledButton from '../../atoms/common/StyledButton';
import { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';

const Navbar = ({ onTop }: { onTop: boolean }) => {
  const [activeTab, setActiveTab] = useState<'home' | 'hire' | 'other'>(
    window.location.pathname.split('/').pop() === 'hire' ? 'hire' : 'home'
  );
  const [selectValue, setSelectValue] = useState<
    'Home' | 'Hire' | 'Competitions'
  >(window.location.pathname.split('/').pop() === 'hire' ? 'Hire' : 'Home');

  const navigate = useNavigate();

  useEffect(() => {
    let path = window.location.pathname;
    let newPath;

    switch (selectValue) {
      case 'Home':
        newPath = '/landing';
        break;
      case 'Hire':
        newPath = '/landing/hire';
        break;
      case 'Competitions':
        newPath = '/landing/#lcompetitions';
        break;
      default:
        newPath = '/landing';
        break;
    }

    if (path !== newPath) {
      navigate(newPath);
    }
  }, [selectValue]);

  return (
    <div
      className={`${onTop ? 'absolute left-[5%] top-5 w-[90%] rounded-full' : 'sticky left-0 top-0 w-full border-b shadow-md'} z-50 px-1 py-4 transition-all duration-100 sm:px-5`}
      style={{
        backgroundColor:
          window.location.pathname.split('/').pop() === 'landing'
            ? '#ffffff'
            : '#e5e7eb',
      }}
    >
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-3">
          <img src={logo} alt="logo" className="max-w900:w-8" />
          <span className="text-xl font-bold text-primary-color w900:text-4xl">
            Bidayya
          </span>
        </div>
        <div className="items-center gap-3 max-w700:hidden w700:flex w900:gap-6">
          <NavOptions activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
        <select
          value={selectValue}
          onChange={(e) => {
            setSelectValue(e.target.value as 'Home' | 'Hire' | 'Competitions');
          }}
          className="bg-transparent text-lg font-semibold w700:hidden"
        >
          <option value="Home">Home</option>
          <option value="Hire">Hire</option>
          <option value="Competitions">Competitions</option>
        </select>
        <div className="flex items-center gap-3">
          <StyledButton
            type="button"
            className="border border-primary-text font-bold text-primary-color max-w900:!text-sm max-w500:!px-2 max-w500:!py-1"
            variant="secondary"
            label="Login"
            onClick={() => navigate('/signin')}
          />
          <StyledButton
            type="button"
            className="font-bold max-w900:!text-sm max-w500:!px-2 max-w500:!py-1"
            variant="primary"
            label="Sign Up"
            onClick={() => navigate('/signup')}
          />
        </div>
      </div>
    </div>
  );
};

const NavOptions = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: 'home' | 'hire' | 'other';
  setActiveTab: (tab: 'home' | 'hire' | 'other') => void;
}) => {
  return (
    <>
      <Link
        to="/landing"
        className="text-primary-text"
        style={{
          fontWeight: activeTab === 'home' ? 'bold' : 'normal',
        }}
        onClick={() => setActiveTab('home')}
      >
        Home
      </Link>
      <Link
        to="/landing/hire"
        style={{
          fontWeight: activeTab === 'hire' ? 'bold' : 'normal',
        }}
        onClick={() => setActiveTab('hire')}
        className="text-primary-text"
      >
        Hire
      </Link>
      <HashLink
        to="/landing/#lcompetitions"
        className="text-primary-text"
        style={{
          fontWeight: activeTab === 'other' ? 'bold' : 'normal',
        }}
        onClick={() => setActiveTab('other')}
      >
        Competitions
      </HashLink>
    </>
  );
};

export default Navbar;
