import {
  NotificationSettingsBackend,
  NotificationSettingsBackend2,
} from '../types/NotificationSettings';
import { User } from '../types/User';
import { fetchApi } from './utils/fetchApi';

export const googleSignin = ({ token }: { token: string }): Promise<any> => {
  return fetchApi('auth/sign-with-google', {
    method: 'POST',
    body: {
      googleAccessToken: token,
    },
  });
};

export const signin = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<{ user: User }> => {
  return fetchApi<{ user: User }>('auth/signin', {
    method: 'POST',
    body: {
      email,
      password,
    },
  });
};

export const sendForgotPasswordOtp = ({
  email,
}: {
  email: string;
}): Promise<any> => {
  return fetchApi('auth/forget-password', {
    method: 'POST',
    body: {
      email,
    },
  });
};

export const resetPassword = ({
  resetToken,
  password,
}: {
  resetToken: string;
  password: string;
}): Promise<any> => {
  return fetchApi('auth/reset-password', {
    method: 'POST',
    body: {
      password,
    },
    headers: {
      Authorization: `Bearer ${resetToken}`,
    },
  });
};

export const verifyOtpAndSendResetToken = ({
  email,
  otp,
}: {
  email: string;
  otp: string;
}): Promise<any> => {
  return fetchApi('auth/verify-otp-and-send-reset-token', {
    method: 'POST',
    body: {
      email,
      otp,
    },
  });
};

export const signOutUser = (): Promise<any> => {
  return fetchApi('auth/signout', {
    method: 'POST',
  });
};

export const sendVerificationOtpEmail = ({
  email,
  name,
}: {
  email: string;
  name: string;
}): Promise<any> => {
  return fetchApi('auth/send-verification-otp-email', {
    method: 'POST',
    body: {
      email,
      name,
    },
  });
};

export const verifyOtpAndChangeEmail = ({
  newEmail,
  otp,
}: {
  newEmail: string;
  otp: string;
}): Promise<any> => {
  return fetchApi('auth/current-user/verify-otp-and-change-email', {
    method: 'PATCH',
    body: {
      newEmail,
      otp,
    },
  });
};

export const changePassword = ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string;
  newPassword: string;
}): Promise<any> => {
  return fetchApi('auth/current-user/change-password', {
    method: 'PATCH',
    body: {
      currentPassword,
      newPassword,
    },
  });
};

export const deleteAccount = ({
  reasons,
}: {
  reasons: string;
}): Promise<any> => {
  return fetchApi('auth/current-user/delete-account', {
    method: 'DELETE',
    body: {
      reasons,
    },
  });
};

export const getNotificationsSetting =
  (): Promise<NotificationSettingsBackend> => {
    return fetchApi<NotificationSettingsBackend>(
      '/auth/current-user/notification-settings',
      {
        method: 'GET',
      }
    );
  };

export const getNotificationsSettingOnAfterOffAll =
  (): Promise<NotificationSettingsBackend> => {
    return fetchApi<NotificationSettingsBackend>(
      '/auth/current-user/notification-settings/on-after-offall',
      {
        method: 'PATCH',
      }
    );
  };

export const updateNotificationSetting = ({
  settings,
}: {
  settings: NotificationSettingsBackend2;
}): Promise<any> => {
  return fetchApi('auth/current-user/notification-settings', {
    method: 'PATCH',
    body: {
      ...settings,
    },
  });
};

export const sendVerificationOtpAndVerifyRecaaptcha = ({
  email,
  name,
  gRecaptchaToken,
}: {
  email: string;
  name: string;
  gRecaptchaToken: string;
}): Promise<any> => {
  return fetchApi('auth/send-verification-otp-email-and-verify-recaptcha', {
    method: 'POST',
    body: {
      email,
      name,
      gRecaptchaToken,
    },
  });
};

export const verifyOtpAndSignup = ({
  email,
  full_name,
  password,
  otp,
}: {
  email: string;
  full_name: string;
  password: string;
  otp: string;
}): Promise<any> => {
  return fetchApi('auth/verify-otp-and-signup', {
    method: 'POST',
    body: {
      email,
      full_name,
      password,
      otp,
    },
  });
};

export const validateToken = (): Promise<any> => {
  return fetchApi('auth/validate-token', {
    method: 'GET',
  });
};
