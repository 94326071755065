import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="flex w-full justify-between gap-9 bg-primary-color p-10 max-lg:flex-col max-lg:items-center">
      <div className="flex flex-col gap-3 lg:text-left">
        <h2 className="text-2xl font-bold text-white">Bidayya</h2>
        <div>
          <p className="text-lg font-semibold text-white">Where Creativity</p>
          <p className="text-lg font-semibold text-white">Meets Opportunity</p>
        </div>
        <div className="flex items-center gap-3 max-lg:justify-center">
          <div className="rounded-full border border-white p-3">
            <FaFacebookF className="text-white" />
          </div>
          <div className="rounded-full border border-white p-3">
            <FaInstagram className="text-white" />
          </div>
          <div className="rounded-full border border-white p-3">
            <FaTwitter className="text-white" />
          </div>
        </div>
        <div>
          <p className="text-lg font-semibold text-white">
            2023 all Right Reserved Term of use
          </p>
          <p className="text-lg font-semibold text-white">Bidayya</p>
        </div>
      </div>

      <div className="flex flex-wrap gap-20 text-left max-lg:flex-col lg:pr-20">
        <div className="flex flex-col gap-2 max-lg:text-center">
          <h3 className="text-xl font-bold text-white">Information</h3>
          <a className="text-lg text-white" href="/#">
            About Us
          </a>
          <a className="text-lg text-white" href="/#">
            Product
          </a>
          <a className="text-lg text-white" href="/#">
            Blog
          </a>
        </div>

        <div className="flex flex-col gap-2 max-lg:text-center">
          <h3 className="text-xl font-bold text-white">Company</h3>
          <a className="text-lg text-white" href="/#">
            Community
          </a>
          <a className="text-lg text-white" href="/#">
            Career
          </a>
          <a className="text-lg text-white" href="/#">
            Our Story
          </a>
        </div>

        <div className="flex flex-col gap-2 max-lg:text-center">
          <h3 className="text-xl font-bold text-white">Contact</h3>
          <a className="text-lg text-white" href="/#">
            Getting Started
          </a>
          <a className="text-lg text-white" href="/#">
            Pricing
          </a>
          <a className="text-lg text-white" href="/#">
            Resources
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
