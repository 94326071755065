import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { signIn, signOut } from '../store/UserSlice';
import { RootState } from '../store';

const useCheckAuthentication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const validateToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/validate-token`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        dispatch(signOut());
        setIsAuthenticated(false);
        return;
      }

      const data = await response.json();
      if (data.user) {
        dispatch(signIn({ user: data.user }));
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error('Error validating token:', error);
      setIsAuthenticated(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validateToken();
  }, []);

  return { isAuthenticated, isLoading };
};

export default useCheckAuthentication;
