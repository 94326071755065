import { useCallback, useEffect, useState } from 'react';
import { ProfileSubmission } from '../../types';
import goldMedal from '../../assets/goldMedal.svg';
import silverMedal from '../../assets/silverMedal.svg';
import bronzeMedal from '../../assets/bronzeMedal.svg';
import ImageComponent from '../../atoms/common/ImageComponent';
import ButtonIcon from '../../atoms/common/ButtonIcon';
import ProjectMenu from './ProjectMenu';
import { useParams } from 'react-router-dom';

const ProfileSubmissions = () => {
  const { id } = useParams<{ id: string }>();
  const [submissions, setSubmissions] = useState<ProfileSubmission[]>([]);
  const fetchSubmissions = async () => {
    const url =
      window.location.pathname.split('/')[1] === 'your-work'
        ? `${process.env.REACT_APP_API_URL}/submissions/my-submissions`
        : `${process.env.REACT_APP_API_URL}/submissions/public/${id}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSubmissions(data);
      } else {
        console.log('Error fetching submissions');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSubmissions();
  }, []);

  const handleDeleteSubmission = (id: number) => {
    setSubmissions(submissions.filter((submission) => submission.id !== id));
  };

  const deleteCallback = useCallback(handleDeleteSubmission, [
    handleDeleteSubmission,
  ]);

  const handleChangePrivacy = (id: number) => {
    setSubmissions(
      submissions.map((submission) =>
        submission.id === id
          ? { ...submission, is_private: !submission.is_private }
          : submission
      )
    );
  };

  const changePrivacyCallback = useCallback(handleChangePrivacy, [
    handleChangePrivacy,
  ]);

  return (
    <div>
      <div className="grid grid-cols-1 gap-4 p-4 w900:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        {submissions.map((submission) => (
          <ProjectCard
            key={submission.id}
            submission={submission}
            deleteCallback={deleteCallback}
            changePrivacyCallback={changePrivacyCallback}
          />
        ))}
      </div>
    </div>
  );
};

const ProjectCard = ({
  submission,
  deleteCallback,
  changePrivacyCallback,
}: {
  submission: ProfileSubmission;
  deleteCallback: (id: number) => void;
  changePrivacyCallback: (id: number) => void;
}) => {
  const [upvoteCount, setUpvoteCount] = useState(
    submission._count.submissionVotes
  );
  const [hasUpvoted, setHasUpvoted] = useState(submission.upvotedByMe);

  const toggleVote = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/submissions/${submission.id}/toggle-upvote`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        if (hasUpvoted) {
          setUpvoteCount(upvoteCount - 1);
        } else {
          setUpvoteCount(upvoteCount + 1);
        }
        setHasUpvoted(!hasUpvoted);
      } else {
        console.log('Error toggling vote');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      onClick={() =>
        (window.location.href = `/competitions/${submission.competition_id}/submissions/${submission.id}`)
      }
      className="mx-auto w-[320px] rounded-xl border"
    >
      <div className="flex items-center gap-2 p-2">
        <div>
          {submission.medal_type === 'GOLD' && (
            <img src={goldMedal} alt="gold medal" className="w-[30px]" />
          )}
          {submission.medal_type === 'SILVER' && (
            <img src={silverMedal} alt="silver medal" className="w-[30px]" />
          )}
          {submission.medal_type === 'BRONZE' && (
            <img src={bronzeMedal} alt="bronze medal" className="w-[30px]" />
          )}
        </div>
        <h3 className="font-bold">{submission.title}</h3>
        <div className="flex-grow"></div>
        {submission.is_winner === true && (
          <i className="pi pi-star-fill text-yellow-400"></i>
        )}
      </div>
      <ImageComponent
        src={submission.cover_image}
        imageName={submission.title}
        className="w-full rounded-none shadow-none"
      />

      <div className="flex items-center justify-between p-2">
        <ButtonIcon
          icon={hasUpvoted ? 'pi pi-sort-up-fill' : 'pi pi-sort-up'}
          className={'rounded-full border border-primary-text px-2 py-1'}
          label={`${upvoteCount}`}
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            toggleVote();
          }}
        />
        {window.location.pathname.split('/')[1] === 'your-work' && (
          <ProjectMenu
            type="submission"
            editProject={() => {}}
            isRunning={submission.competition.state === 'ONGOING'}
            submissionId={submission.id}
            competitionId={submission.competition_id}
            deleteCallback={deleteCallback}
            changePrivacyCallback={changePrivacyCallback}
            is_private={submission.is_private}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileSubmissions;
