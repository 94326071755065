import React, { ReactNode } from 'react';
import Header from './header/Header';
import SidebarComponent from './sidebar/SidebarComponent';
import { useExpandHover } from '../hooks/ExpandHoverContext';

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { expanded, hover } = useExpandHover();
  return (
    <div className="flex w-full">
      <Header />
      <SidebarComponent />
      <div
        className={`mx-auto mt-12 max-w-[100rem] flex-1 overflow-hidden px-2 md:px-4 lg:px-8 ${expanded || hover ? 'ml-64' : 'ml-20'}`}
      >
        {children}
      </div>
    </div>
  );
};

export default MainLayout;
