import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CompetitionAccessType,
  CompetitionDetails,
  CompetitionState,
  CompetitionTag,
} from '../types/CompetitionDetails';
import { Tag } from '../types';
import { editCompetition, getCompetitionById } from '../api/competionApi';
import { useToast } from '../ctx/Toast';
import MainLayout from '../components/MainLayout';
import CompetitionForm from '../components/createCompetition/CompetitionForm';
import ProtectedPage from './ProtectedPage';
import { getTags } from '../api/tagsApi';

const EditCompetition = () => {
  const { competitionUrl } = useParams();
  const [competition, setCompetition] = useState<CompetitionDetails>();
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [membersFile, setMembersFile] = useState<File[]>([]);
  const [companyName, setCompanyName] = useState<string>('');
  const [price, setPrice] = useState<number>(0);
  const [rules, setRules] = useState<string>('');
  const [overview, setOverview] = useState<string>('');
  const [competitionUrlS, setCompetitionUrlS] = useState<string>('');
  const [tags, setTags] = useState<Tag[]>([]);
  const [submissionDeadline, setSubmissionDeadline] = useState<string>(
    new Date().toISOString().split('T')[0]
  );
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [accessType, setAccessType] = useState<CompetitionAccessType>(
    CompetitionAccessType.ANYONE
  );

  useEffect(() => {
    if (competitionUrl !== undefined) {
      getCompetitionById({ id: competitionUrl })
        .then((data) => {
          setCompetition(data);
        })
        .catch((error) => {});
    }
  }, []);

  const urlToFile = async (url: string, fileName: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
  };

  const convertCoverAndFiles = async (
    coverUrl: string,
    filesUrls: string[]
  ): Promise<{ coverFile: File; files: File[] }> => {
    const coverFile = await urlToFile(coverUrl, coverUrl.split('/').pop()!);

    const files = await Promise.all(
      filesUrls.map(async (fileUrl, index) => {
        return await urlToFile(fileUrl, fileUrl.split('/').pop()!);
      })
    );

    return { coverFile, files };
  };

  useEffect(() => {
    if (competition) {
      setTitle(competition.name);
      setDescription(competition.description);
      setCompanyName(competition.company.company_name);
      setPrice(competition.price);
      setRules(competition.rules);
      setOverview(competition.overview);
      setCompetitionUrlS(competition.competition_url);
      setSubmissionDeadline(
        new Date(competition.submission_deadline).toISOString().split('T')[0]
      );
      setIsPrivate(competition.is_private);
      setAccessType(competition.access_type);

      convertCoverAndFiles(competition.cover_image, competition.files).then(
        ({ coverFile, files }) => {
          setCoverImage(coverFile);
          setFiles(files);
        }
      );

      getTags()
        .then((data) => {
          setTags(
            data.map((tag: Tag) => {
              return {
                tag_id: tag.tag_id,
                tag_name: tag.tag_name,
                selected: competition.tags.some(
                  (ctag) => ctag.tag_id === tag.tag_id
                ),
              };
            })
          );
        })
        .catch((error) => {});
    }
  }, [competition]);

  const [urlExists, setUrlExists] = useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (competitionUrl !== undefined && !urlExists) {
      setButtonClicked(true);
      editCompetition({
        id: competitionUrl!,
        files: files,
        cover_image: coverImage as File,
        members_file: membersFile,
        competition_name: title,
        description: description,
        company_name: companyName,
        price: price,
        rules: rules,
        overview: overview,
        competition_url: competitionUrlS,
        tags: tags.filter((tag) => tag.selected).map((tag) => tag.tag_id),
        submission_deadline: submissionDeadline,
        is_private: isPrivate,
        access_type: accessType,
      }).finally(() => {
        if (competition?.state === CompetitionState.NEW) {
          navigate(`/host-dashboard/new/${competitionUrl}`);
        } else if (competition?.state === CompetitionState.UNDER_REVIEW) {
          navigate(`/host-dashboard/under-review/${competitionUrl}`);
        } else if (competition?.state === CompetitionState.ONGOING) {
          navigate(`/host-dashboard/running/${competitionUrl}`);
        } else if (competition?.state === CompetitionState.DECLINED) {
          navigate(`/host-dashboard/declined/${competitionUrl}`);
        } else if (competition?.state === CompetitionState.ENDED) {
          navigate(`/host-dashboard/ended/${competitionUrl}`);
        } else if (competition?.state === CompetitionState.RATED) {
          navigate(`/host-dashboard/rated/${competitionUrl}`);
        }
      });
    }
  };

  return (
    <ProtectedPage>
      <MainLayout>
        <CompetitionForm
          buttonClicked={buttonClicked}
          edit={true}
          onSubmit={handleSubmit}
          setUrlExists={setUrlExists}
          urlExists={urlExists}
          title={title}
          setTitle={setTitle}
          description={description}
          setDescription={setDescription}
          coverImage={coverImage}
          setCoverImage={setCoverImage}
          membersFile={membersFile}
          setMembersFile={setMembersFile}
          files={files}
          setFiles={setFiles}
          companyName={companyName}
          setCompanyName={setCompanyName}
          price={price}
          setPrice={setPrice}
          rules={rules}
          setRules={setRules}
          overview={overview}
          setOverview={setOverview}
          competitionUrlS={competitionUrlS}
          setCompetitionUrlS={setCompetitionUrlS}
          tags={tags}
          setTags={setTags}
          submissionDeadline={submissionDeadline}
          setSubmissionDeadline={setSubmissionDeadline}
          isPrivate={isPrivate}
          setIsPrivate={setIsPrivate}
          accessType={accessType}
          setAccessType={setAccessType}
        />
      </MainLayout>
    </ProtectedPage>
  );
};

export default EditCompetition;
