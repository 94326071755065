import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useCheckAuthentication from '../hooks/useCheckAuthentication';
import Loading from '../components/general/Loading';

const ProtectedPage = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, isLoading } = useCheckAuthentication();
  const location = useLocation();

  const publicOnly =
    location.pathname === '/' ||
    location.pathname === '/signup' ||
    location.pathname === '/forgot-password' ||
    location.pathname === '/signin';

  if (isLoading) {
    return (
      <div className="flex h-[100vw] w-[100vh] items-center justify-center">
        <Loading />;
      </div>
    );
  }

  if (publicOnly && isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  // If the page is protected and the user is not authenticated, redirect to landing
  if (!publicOnly && !isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default ProtectedPage;
