import React from 'react';
import MainLayout from '../components/MainLayout';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import ProtectedPage from './ProtectedPage';

const Home: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.user);
  return (
    <ProtectedPage>
      <MainLayout>
        <div className="my-24 lg:mx-8 lg:my-10">
          <header className="mb-4 flex flex-col justify-between gap-3 lg:gap-4">
            <h1 className="text-left text-4xl font-bold">
              Welcome, {user?.full_name}
            </h1>
            <p className="lg:text-md text-left text-xs text-secondary-text md:w-96">
              You're on a roll! Jump back in, or start something new.
            </p>
          </header>
          <div className="mb-16 flex w-full items-center justify-around">
            <StatisticsField
              icon="pi-users pi"
              title="Competitions"
              value="0"
            />
            <StatisticsField icon="pi-chart-line pi" title="Course" value="0" />
            <StatisticsField
              icon="pi-comments pi"
              title="Discussions"
              value="0"
            />
            <StatisticsField
              icon="pi-briefcase pi"
              title="Submission"
              value="0"
            />
          </div>
          <hr className="border-1" />
        </div>
      </MainLayout>
    </ProtectedPage>
  );
};

export const StatisticsField = ({
  icon,
  title,
  value,
  ranking,
}: {
  icon: string;
  title: string;
  value: string;
  ranking?: boolean;
}) => {
  return (
    <div className="flex aspect-square h-[150px] w-[150px] transform flex-col items-center gap-2 rounded-lg bg-white p-4 shadow-lg transition duration-500 hover:scale-105 hover:shadow-2xl">
      <div
        className={`h-[30px] w-[30px] rounded-full ${ranking ? 'bg-green-400' : 'bg-light-blue'} p-1`}
      >
        <i className={`text-black ${icon}`}></i>
      </div>
      <p className="text-xs text-gray-600">{title}</p>
      <h2 className="text-2xl font-bold">{value}</h2>
    </div>
  );
};

export default Home;
