import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useRef, useState } from 'react';
import StyledButton from '../../atoms/common/StyledButton';

const ProjectMenu = ({
  type,
  isRunning,
  editProject,
  submissionId,
  competitionId,
  deleteCallback,
  is_private,
  changePrivacyCallback,
}: {
  type: 'project' | 'submission';
  isRunning: boolean;
  editProject: () => void;
  submissionId: number;
  competitionId: number;
  deleteCallback: (id: number) => void;
  is_private: boolean;
  changePrivacyCallback: (id: number) => void;
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);

  const handleBlur = (e: React.FocusEvent<HTMLButtonElement>) => {
    if (!menuRef.current?.contains(e.relatedTarget as Node)) {
      setDropdownOpen(false);
    }
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${type === 'project' ? 'projects' : 'submissions'}/${submissionId}`,
        {
          method: 'DELETE',
          credentials: 'include',
        }
      );
      if (response.ok) {
        deleteCallback(submissionId);
        setConfirmDeletion(false);
      } else {
        console.error(response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTogglePrivacy = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${type === 'project' ? 'projects' : 'submissions'}/${submissionId}/toggle-${type === 'project' ? 'hide' : 'private'}`,
        {
          method: 'PATCH',
          credentials: 'include',
        }
      );
      if (response.ok) {
        changePrivacyCallback(submissionId);
        setConfirmTogglePrivacy(false);
      } else {
        console.error(response.statusText);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [confirmDeletion, setConfirmDeletion] = useState(false);
  const [confirmTogglePrivacy, setConfirmTogglePrivacy] = useState(false);

  return (
    <div className="relative">
      <Button
        className="rounded-full px-2 py-1"
        icon="pi pi-ellipsis-h"
        onClick={(e) => {
          e.stopPropagation();
          setDropdownOpen(!isDropdownOpen);
        }}
        onBlur={handleBlur}
      ></Button>
      {isDropdownOpen && (
        <div
          ref={menuRef}
          className="absolute right-0 mt-2 w-40 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div className="py-1">
            {isRunning && (
              <a
                href={`/competitions/${competitionId}/submit/${submissionId}`}
                className="flex w-full flex-row items-center px-4 py-2 text-left text-sm hover:bg-gray-100"
              >
                <i className="pi pi-pencil mr-2"></i>
                Edit
              </a>
            )}
            {type === 'project' && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  editProject();
                }}
                className="flex w-full flex-row items-center px-4 py-2 text-left text-sm hover:bg-gray-100"
              >
                <i className="pi pi-pencil mr-2"></i>
                Edit
              </button>
            )}
            <button
              onClick={(e) => {
                e.stopPropagation();
                setConfirmDeletion(true);
              }}
              className="flex w-full flex-row items-center px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-100"
            >
              <i className="pi pi-trash mr-2"></i>
              Delete
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setConfirmTogglePrivacy(true);
              }}
              className="flex w-full flex-row items-center px-4 py-2 text-left text-sm hover:bg-gray-100"
            >
              <i className="pi pi-eye-slash mr-2"></i>
              {is_private ? 'Unhide' : 'Hide'}
            </button>
          </div>
        </div>
      )}
      <Dialog
        header="Confirm Deletion"
        visible={confirmDeletion}
        style={{
          width: '90vw',
          maxWidth: '800px',
          padding: '.2rem',
        }}
        onHide={() => {
          setConfirmDeletion(false);
        }}
        draggable={false}
        onClick={(e) => e.stopPropagation()}
      >
        <p>
          Are you sure you want to delete this {type}? This action cannot be
          undone.
        </p>
        <div className="mt-6 flex justify-end gap-4">
          <StyledButton
            label="Confirm"
            variant="primary"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              handleDelete();
            }}
          />
          <StyledButton
            label="Cancel"
            variant="secondary"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              setConfirmDeletion(false);
            }}
          />
        </div>
      </Dialog>
      <Dialog
        header="Confirm Privacy Change"
        visible={confirmTogglePrivacy}
        style={{
          width: '90vw',
          maxWidth: '800px',
          padding: '.2rem',
        }}
        onHide={() => {
          setConfirmTogglePrivacy(false);
        }}
        draggable={false}
        onClick={(e) => e.stopPropagation()}
      >
        <p>Are you sure you want to change the privacy of this {type}?</p>
        <div className="mt-6 flex justify-end gap-4">
          <StyledButton
            label="Confirm"
            variant="primary"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              handleTogglePrivacy();
            }}
          />
          <StyledButton
            label="Cancel"
            variant="secondary"
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              setConfirmTogglePrivacy(false);
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ProjectMenu;
