import { Comment } from '../../types';
import { timeAgo } from '../../utils';
import goldMedal from '../../assets/goldMedal.svg';
import silverMedal from '../../assets/silverMedal.svg';
import bronzeMedal from '../../assets/bronzeMedal.svg';
import ButtonIcon from '../common/ButtonIcon';
import { Avatar } from 'primereact/avatar';
import StyledButton from '../common/StyledButton';
import { useState } from 'react';

const RepliesContainer = ({
  replies,
  toggleVote,
  commentId,
  updateComment,
}: {
  replies: Comment[];
  toggleVote: any;
  commentId: number;
  updateComment: any;
}) => {
  const [repliesFetched, setRepliesFetched] = useState(false);

  const fetchReplies = async (commentId: number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${commentId}/replies`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        updateComment(commentId, data);
        setRepliesFetched(true);
      } else {
        console.log('Error fetching replies');
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="mt-2 border-l pl-6">
      {replies.map((reply, replyIndex) => (
        <>
          <div key={replyIndex} className="mt-2 flex w-full items-start">
            <Avatar
              image={reply.image_path || ''}
              size="normal"
              shape="circle"
              className="mr-2"
            />
            <div>
              <p className="font-bold">{reply.user.full_name}</p>
              <p className="text-gray-700">
                Posted {timeAgo(new Date(reply.created_at))}
              </p>
              <p
                className="text-gray-700"
                dangerouslySetInnerHTML={{
                  __html: reply.content,
                }}
              />
            </div>
            <div className="flex-grow"></div>
            <div className="flex items-start justify-center gap-3">
              <div className="flex flex-col items-center justify-center gap-1">
                <ButtonIcon
                  icon={
                    reply.upvotedByMe ? 'pi pi-sort-up-fill' : 'pi pi-sort-up'
                  }
                  className="rounded-full border border-primary-text px-2 py-1"
                  label={`${reply._count.commentVotes}`}
                  onClick={() => toggleVote(commentId, reply.id)}
                />
              </div>
              <div>
                {reply.medal_type === 'GOLD' && (
                  <img src={goldMedal} alt="Gold Medal" className="h-8 w-8" />
                )}
                {reply.medal_type === 'SILVER' && (
                  <img
                    src={silverMedal}
                    alt="Silver Medal"
                    className="h-8 w-8"
                  />
                )}
                {reply.medal_type === 'BRONZE' && (
                  <img
                    src={bronzeMedal}
                    alt="Bronze Medal"
                    className="h-8 w-8"
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ))}
      {!repliesFetched && (
        <StyledButton
          className="mt-2"
          onClick={() => fetchReplies(commentId)}
          variant="primary"
          label="View All Replies"
        />
      )}
    </div>
  );
};

export default RepliesContainer;
