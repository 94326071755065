import { useEffect, useState } from 'react';
import { useExpandHover } from '../hooks/ExpandHoverContext';
import { ProfileProject } from '../types';
import { useParams } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import ImageComponent from '../atoms/common/ImageComponent';
import PDFs from '../atoms/common/PDFs';
import ProtectedPage from './ProtectedPage';

const ProjectPage = () => {
  const { expanded } = useExpandHover();

  const [project, setProject] = useState<ProfileProject>();
  const { projectId } = useParams();
  const fetchProject = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projects/${projectId}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setProject(data);
      } else {
        console.error('Error fetching submission:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching submission:', error);
    }
  };

  useEffect(() => {
    fetchProject();
  }, []);
  return (
    <ProtectedPage>
      <MainLayout>
        <div
          className={`my-24 transition-all duration-300 ${
            expanded ? 'mx-0' : 'mx-1'
          }`}
        >
          <ProjectDetails project={project!} />
        </div>
      </MainLayout>
    </ProtectedPage>
  );
};

const ProjectDetails = ({ project }: { project: ProfileProject }) => {
  const imageFiles = project?.files.filter(
    (file) => file.split('.').pop() !== 'pdf'
  );
  const pdfFiles = project?.files.filter(
    (file) => file.split('.').pop() === 'pdf'
  );
  return (
    <div className="text-start">
      <header
        className={`mb-4 mt-8 flex flex-col items-start justify-between gap-3 lg:flex-row`}
      >
        <div className="flex flex-col gap-3 text-start lg:gap-4">
          <h1 className="text-2xl font-bold">Project Details</h1>
          <h2 className="text-xl font-bold sm:text-4xl">{project?.title}</h2>
        </div>
        <div className="flex flex-col gap-3 lg:gap-4">
          <ImageComponent
            className="h-40 w-64"
            src={project?.cover_image || ''}
          />
        </div>
      </header>
      <hr />
      <div
        className={`mt-3 ${imageFiles && imageFiles.length > 0 && 'grid'} grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3`}
      >
        {imageFiles && imageFiles.length > 0 ? (
          imageFiles.map((file: any, index: number) => (
            <ImageComponent
              key={index}
              src={file}
              imageName={file || `Image ${index + 1}`}
              className="h-auto w-full"
            />
          ))
        ) : (
          <h3>No images available</h3>
        )}
      </div>
      <div className="mt-4">
        {pdfFiles && pdfFiles.length > 0 ? (
          <PDFs PDFs={pdfFiles} />
        ) : (
          <h3>No PDF files available</h3>
        )}
      </div>
      <p className="mt-5 text-start">{project?.description}</p>
    </div>
  );
};

export default ProjectPage;
