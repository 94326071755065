import HireHero1 from '../../assets/HireHero1.svg';
import HireHero2 from '../../assets/HireHero2.svg';
import HireHero3 from '../../assets/HireHero3.svg';

const HireHero = () => {
  return (
    <div className="relative flex h-screen items-center justify-around overflow-hidden bg-white pt-[15vh] max-xl:px-5 max-lg:flex-col 2xl:px-[200px] 2xl:pt-[20vh]">
      <div className="flex flex-col gap-3 text-center text-7xl font-extrabold">
        <h2>Hire the most</h2>
        <h2 className="text-primary-color">passionate designer</h2>
        <p>in the Arab world</p>
        <p className="text-xl">
          Create competition and let the most passionate designer impress you.
        </p>
      </div>
      <div className="relative h-[513px] w-[480px] shrink-0 rounded-[50%]">
        <img
          src={HireHero1}
          alt=""
          className="absolute left-[45%] top-[47%] aspect-square max-w-[413px] -translate-x-1/2 -translate-y-1/2"
        />
        <img src={HireHero2} alt="" className="absolute -left-[55%] top-[5%]" />
      </div>

      <div className="absolute -left-[132px] top-[23vh] h-[307px] w-[264px] rounded-[50%] border-2 border-dashed border-primary-color"></div>
      <img src={HireHero3} className="absolute left-[70px] top-[40vh]" alt="" />
    </div>
  );
};

export default HireHero;
