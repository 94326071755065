import { useEffect, useState } from 'react';
import { Submission } from '../types';
import MainLayout from '../components/MainLayout';
import { useParams } from 'react-router-dom';
import SubmissionDetails from '../atoms/competition/SubmissionDetails';
import { useExpandHover } from '../hooks/ExpandHoverContext';

const SubmissionPage = () => {
  const { expanded } = useExpandHover();

  const [submission, setSubmission] = useState<Submission>();
  const { submissionId } = useParams();
  const fetchSubmission = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/submissions/${submissionId}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log('Submission:', data);
        setSubmission(data);
      } else {
        console.error('Error fetching submission:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching submission:', error);
    }
  };

  useEffect(() => {
    fetchSubmission();
  }, []);
  return (
    <MainLayout>
      <div
        className={`my-24 transition-all duration-300 lg:mx-8 ${
          expanded ? 'mx-0' : 'mx-1'
        }`}
      >
        <SubmissionDetails submission={submission} />
      </div>
    </MainLayout>
  );
};

export default SubmissionPage;
