import { Button } from 'primereact/button';
import backIcon from '../../assets/back.png';
import { buttonVariantStyles } from '../../styles';
import addIcon from '../../assets/images/add.png';
import { ButtonVariant } from '../../types/ButtonVariant';

const StyledButton = ({
  type = 'button',
  onClick,
  label,
  variant = 'primary',
  className = '',
  disabled = false,
  icon = '',
  rounded = false,
  title = '',
  children,
}: {
  type?: 'button' | 'submit' | 'reset';
  onClick?: any;
  label?: string;
  variant?: ButtonVariant;
  className?: string;
  disabled?: boolean;
  icon?: string;
  rounded?: boolean;
  title?: string;
  children?: any;
}) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      className={`${buttonVariantStyles[variant]} ${className}`}
      style={{
        borderRadius: rounded ? '9999px' : '',
      }}
      disabled={disabled}
      title={title}
    >
      {children}
      {variant === 'pdf' ? (
        <>
          <i className="pi pi-file-pdf mr-2 text-red-600"></i>
          {label}
        </>
      ) : icon === 'back' ? (
        <>
          <img
            src={backIcon}
            alt="Back"
            className="mr-2 inline-block h-4 w-4"
            loading="lazy"
          />

          {label}
        </>
      ) : icon === 'addTopic' ? (
        <>
          <img
            src={addIcon}
            alt="Back"
            className="mr-2 inline-block w-[20px]"
            loading="lazy"
          />

          {label}
        </>
      ) : (
        <div className="mx-auto">{label}</div>
      )}
    </Button>
  );
};

export default StyledButton;
