import {} from '../types';
import { ActiveCompetition } from '../types/ActiveCompetition';
import { CompetitionCreate } from '../types/CompetitionCreate';
import { Notifications } from '../types/Notifications';
export const initialCompetitions: CompetitionCreate[] = [
  {
    id: '1',
    competitionName: 'Competition Example',
    description: 'Competition Example description',
    companyName: 'Competition Example Company Name',
    price: 1000,
    rules: 'Competition rules',
    overview: 'Competition overview',
    competitionUrl: 'https://www.kaggle.com/',
    tags: [
      {
        tag_id: 1,
        tag_name: 'tag1',
        selected: true,
      },
      {
        tag_id: 2,
        tag_name: 'tag2',
        selected: false,
      },
    ],
    submissionDeadline: '2024-12-31',
    isPrivate: false,
    accessType: 'ANYONE',
    files: [],
    coverImage: null,
    joinAvailability: 'anyone',
    termsAccepted: true,
  },
  {
    id: '12345',
    competitionName: 'Competition Name',
    description: 'Scott Crossley',
    companyName: 'Company Name',
    price: 0,
    rules: '',
    overview: 'Competition overview',
    competitionUrl: '#',
    submissionDeadline: '2024-12-31',
    isPrivate: false,
    accessType: 'ANYONE',
    files: [],
    coverImage: null,
    tags: [],
    joinAvailability: 'open',
    termsAccepted: true,
  },
];

export const initialActiveCompetitions: ActiveCompetition[] = [
  {
    title: 'Active competition',
    submissionsLeft: 10,
    timeAgo: '2 hours ago',
    rank: '1st',
    totalParticipants: 100,
  },
  {
    title: 'Another active competition',
    submissionsLeft: 5,
    timeAgo: '1 hour ago',
    rank: '2nd',
    totalParticipants: 50,
  },
];

export const passwordRegex: RegExp =
  /^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[!@#$%^&()])[a-zA-Z0-9!@#$%^&()]{8,}$/;

export const passwordCapitalLetterRegex: RegExp = /[A-Z]/;
export const passwordSmallLetterRegex: RegExp = /[a-z]/;
export const passwordNumberRegex: RegExp = /[0-9]/;
export const passwordSpecialCharacterRegex: RegExp = /[!@#$%^&()]/;
export const passwordLengthRegex: RegExp = /^.{8,}$/;

export const emailRegex: RegExp =
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const notificationsData: Notifications = {
  general: {
    title: 'General notification',
    competitions: {
      title: 'Competition you participated in',
      settings: {
        new: {
          description: 'New added competition',
          value: false,
        },
        ended: {
          description: 'Competition Ended',
          value: false,
        },
        result: {
          description: 'Competition Results Published',
          value: false,
        },
        deadlineRemind: {
          description:
            'Competition Deadline Reminder (10 days and 5 days before closing)',
          value: false,
        },
      },
    },
  },
  alerts: {
    title: 'Alerts About Your Work',
    achievements: {
      title: 'Your Bidayya achievements',
      settings: {
        achievements: {
          description: 'Notifications when you receive a medal',
          value: false,
        },
      },
    },
  },
  discussions: {
    title: 'Discussions with Bidayya Users',
    comments: {
      title: 'Comments and Topics',

      settings: {
        newCommentsOnMyTopic: {
          description: 'New replies in one of my topics',
          value: false,
        },
        newRepliesOnAComment: {
          description: "Replies on comment you've commented on other topic",
          value: false,
        },
        newVotesOnATopic: {
          description: 'Upvotes on one of my topics',
          value: false,
        },
        newVotesOnAComment: {
          description: 'Upvotes on one of my comments',
          value: false,
        },
        newCommentOnATopicICommentedOn: {
          description: "Comments on topics you've commented on",
          value: false,
        },
      },
    },
  },
  users: {
    title: 'Users',

    newFollowers: {
      title: 'New Followers',
      settings: {
        newFollowers: {
          description: 'Notification when someone starts following you',
          value: false,
        },
      },
    },
  },
};
export const sectionData = {
  alerts: [
    {
      title: 'All',
      settings: {
        value: true,
      },
    },
    {
      title: 'Competitions you can share',
      settings: {
        value: true,
      },
    },
    {
      title: 'Competition team you join',
      settings: {
        value: true,
      },
    },
    {
      title: 'Notebook you own',
      settings: {
        value: true,
      },
    },
  ],
  discussions: [
    {
      title: 'All',
      settings: {
        value: true,
      },
    },
    {
      title: '@mentions by Kaggle users you follow',
      settings: {
        value: true,
      },
    },
    {
      title: "@mentions by Kaggle users you don't follow",
      settings: {
        value: true,
      },
    },
  ],
  comments: [
    {
      title: "New replies in a topic I'm following",
      settings: {
        value: true,
      },
    },
    {
      title: "New topics in a forum I'm following",
      settings: {
        value: true,
      },
    },
    {
      title: 'Upvotes on one of my topics',
      settings: {
        value: true,
      },
    },
    {
      title: "Comments on topics you've commented on",
      settings: {
        value: true,
      },
    },
  ],
  achievements: [
    {
      title:
        "Notifications when you receive a medal or are promoted on Bidayya's progression system.",
      settings: {
        value: false,
      },
    },
  ],
};
