import submissionsScreen from '../../assets/submissionsScreen.svg';
import competitionsAndSubms from '../../assets/competitionsAndSubms.svg';

const compeititons = [
  {
    image:
      'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg',
    title: 'Visual Symphony',
    description:
      'Embark on a visual journey where each element harmonizes to create a breathtaking symphony of aesthetics',
    price: '$1000',
  },
  {
    image:
      'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg',
    title: 'Graphic Design',
    description:
      'Elevate your brand with our graphic design expertise, where creativity meets strategy to craft visually compelling narratives that leave a lasting impression',
    price: '$800',
  },
  {
    image:
      'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg',
    title: '3D visual',
    description:
      'Experience a new dimension of creativity with our 3D visuals, where imagination takes shape and ordinary',
    price: '$1500',
  },
];

const CompsAndSubms = () => {
  return (
    <div className="relative max-w-[1480px] max-2xl:mx-3 2xl:mx-auto">
      <img
        src={competitionsAndSubms}
        className="absolute -right-[20%] -z-50 -translate-y-1/2"
        alt=""
      />

      <div className="text-left" id="lcompetitions">
        <h2 className="mt-[30vh] text-3xl font-bold">Competitions</h2>
        <p className="text-xl">
          Hone your design skills in competitions hosted alongside renowned
          businesses, companies, and brands from around the globe.
        </p>
        <div className="mt-20 flex flex-wrap items-center justify-around">
          {compeititons.map((comp) => (
            <CompetitionCard {...comp} />
          ))}
        </div>
      </div>

      <div className="mt-[30vh] flex justify-center gap-6 max-xl:flex-wrap xl:justify-between">
        <div className="flex max-w-[600px] flex-col gap-3 text-left">
          <h2 className="text-xl font-bold">
            Streamline Your Design Process with Bidayya
          </h2>
          <p className="text-lg">
            Discover a user-friendly dashboard designed for businesses,
            simplifying the way you find and reward top design talent.
          </p>
          <h2 className="text-xl font-bold">
            Effortless Talent Discovery and Affordable Talent Access
          </h2>
          <p className="text-lg">
            With Bidayya, sourcing the best design talent becomes seamless.
            Access a multitude of creative submissions, enabling you to easily
            identify and engage with exceptional designers who can bring fresh
            perspectives to your projects.
          </p>
          <h2 className="text-xl font-bold">
            Reward Creativity, Foster Engagement{' '}
          </h2>
          <p className="text-lg">
            Bidayya makes it straightforward for your business to reward
            outstanding designs. Offer prizes and accolades, fostering a
            motivated community of designers eager to contribute their best
            work, ensuring a win-win situation for both your brand and the
            creative minds
          </p>
        </div>
        <img
          src={submissionsScreen}
          alt="submissions"
          className="w-full max-w-[760px]"
        />
      </div>
    </div>
  );
};

const CompetitionCard = ({
  image,
  title,
  description,
  price,
}: {
  image: string;
  title: string;
  description: string;
  price: string;
}) => {
  return (
    <div className="my-5 flex max-w-[400px] flex-col items-center gap-3 rounded-3xl border bg-white p-4">
      <img
        src={image}
        alt=""
        className="aspect-square w-[111px] rounded-full object-cover"
      />
      <h3 className="text-2xl font-bold">{title}</h3>
      <p className="text-center">{description}</p>
      <p className="text-center font-bold">{price}</p>
    </div>
  );
};

export default CompsAndSubms;
