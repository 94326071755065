import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { signIn, signOut } from '../store/UserSlice';
import { validateToken } from '../api/authApi';

const useCheckAuthentication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    validateToken()
      .then((data) => {
        if (data.user) {
          dispatch(signIn({ user: data.user }));
          setIsAuthenticated(true);
        }
      })
      .catch(() => {
        dispatch(signOut());
        setIsAuthenticated(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    sessionStorage.setItem('isAuthenticated', isAuthenticated.toString());
  }, [isAuthenticated]);

  return { isAuthenticated, isLoading };
};

export default useCheckAuthentication;
