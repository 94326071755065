import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Password } from 'primereact/password';
import { formVariantStyles } from '../../styles';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
  passwordCapitalLetterRegex,
  passwordSmallLetterRegex,
  passwordNumberRegex,
  passwordSpecialCharacterRegex,
  passwordLengthRegex,
} from '../../constants';

const FormField = ({
  label,
  type = 'text',
  id,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder = '',
  className = '',
  variant = 'auth',
  maxLength = Infinity,
  forgetPassword = false,
  pickPassword = false,
  setPasswordValid,
}: {
  label: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>['type'];
  id: string;
  value: string | number;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  className?: string;
  variant?: 'primary' | 'auth';
  maxLength?: number;
  forgetPassword?: boolean;
  pickPassword?: boolean;
  setPasswordValid?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const styles = `${className} ${formVariantStyles[variant]} !focus:ring-0 !focus:outline-none`;

  const [passwordCapitalLetter, setPasswordCapitalLetter] =
    useState<boolean>(false);
  const [passwordSmallLetter, setPasswordSmallLetter] =
    useState<boolean>(false);
  const [passwordNumber, setPasswordNumber] = useState<boolean>(false);
  const [passwordSpecialCharacter, setPasswordSpecialCharacter] =
    useState<boolean>(false);
  const [passwordLength, setPasswordLength] = useState<boolean>(false);
  const [passwordFocused, setPasswordFocused] = useState<boolean>(false);

  useEffect(() => {
    setPasswordCapitalLetter(passwordCapitalLetterRegex.test(String(value)));
    setPasswordSmallLetter(passwordSmallLetterRegex.test(String(value)));
    setPasswordNumber(passwordNumberRegex.test(String(value)));
    setPasswordSpecialCharacter(
      passwordSpecialCharacterRegex.test(String(value))
    );
    setPasswordLength(passwordLengthRegex.test(String(value)));
  }, [value]);

  useEffect(() => {
    if (setPasswordValid)
      setPasswordValid(
        passwordSmallLetter &&
          passwordCapitalLetter &&
          passwordNumber &&
          passwordSpecialCharacter &&
          passwordLength
      );
  }, [
    passwordSmallLetter,
    passwordCapitalLetter,
    passwordNumber,
    passwordSpecialCharacter,
    passwordLength,
    setPasswordValid,
  ]);

  const header = <div className="mb-2 font-bold">Pick a password</div>;
  const footer = (
    <>
      {/* <Divider /> */}
      {(passwordFocused || value) && (
        <div className="mb-2">
          <div className="flex items-center">
            <span
              className={`${
                passwordCapitalLetter ? 'text-green-500' : 'text-red-500'
              }`}
            >
              &#10003;
            </span>
            <span className="ml-1">Capital letter</span>
          </div>
          <div className="flex items-center">
            <span
              className={`${
                passwordSmallLetter ? 'text-green-500' : 'text-red-500'
              }`}
            >
              &#10003;
            </span>
            <span className="ml-1">Small letter</span>
          </div>
          <div className="flex items-center">
            <span
              className={`${passwordNumber ? 'text-green-500' : 'text-red-500'}`}
            >
              &#10003;
            </span>
            <span className="ml-1">Number</span>
          </div>
          <div className="flex items-center">
            <span
              className={`${
                passwordSpecialCharacter ? 'text-green-500' : 'text-red-500'
              }`}
            >
              &#10003;
            </span>
            <span className="ml-1">Special !@#$%^&()</span>
          </div>
          <div className="flex items-center">
            <span
              className={`${passwordLength ? 'text-green-500' : 'text-red-500'}`}
            >
              &#10003;
            </span>
            <span className="ml-1">At least 8 characters long</span>
          </div>
        </div>
      )}
    </>
  );

  return (
    <div className={`${variant === 'auth' ? 'mb-2' : ''}`}>
      <label
        htmlFor={id}
        className="mb-1 block text-start text-sm font-medium text-black"
      >
        {label}{' '}
        {variant === 'primary' && <span className="text-red-600">*</span>}
      </label>
      {type === 'textArea' ? (
        <InputTextarea
          id={id}
          value={value?.toString() || ''}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          className={`${styles} h-32`}
          maxLength={maxLength}
        />
      ) : type === 'password' ? (
        <Password
          value={value}
          onChange={onChange}
          className={`${styles} `}
          id={id}
          onFocus={() => {
            onFocus && onFocus();
            if (pickPassword) setPasswordFocused(true);
          }}
          onBlur={() => {
            onBlur && onBlur();
            if (pickPassword) setPasswordFocused(false);
          }}
          placeholder={placeholder}
          maxLength={maxLength}
          toggleMask
          header={pickPassword ? header : null}
          footer={pickPassword ? footer : null}
          feedback={pickPassword}
        />
      ) : (
        <InputText
          id={id}
          type={type}
          value={value?.toString() || ''}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          className={styles}
          maxLength={maxLength}
        />
      )}

      {/* Forgot Password Link */}
      {forgetPassword && (
        <div className="mt-2 flex justify-end">
          <Link to="/forgot-password">
            <span className="block text-xs font-medium text-forgot-password hover:underline">
              Forgot password?
            </span>
          </Link>
        </div>
      )}
    </div>
  );
};

export default FormField;
