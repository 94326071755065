import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProfileProject } from '../../types';
import goldMedal from '../../assets/goldMedal.svg';
import silverMedal from '../../assets/silverMedal.svg';
import bronzeMedal from '../../assets/bronzeMedal.svg';
import ImageComponent from '../../atoms/common/ImageComponent';
import ButtonIcon from '../../atoms/common/ButtonIcon';
import ProjectMenu from './ProjectMenu';
import { Dialog } from 'primereact/dialog';
import { Tag } from '../../types';
import {
  CompetitionFormField,
  TagSelection,
} from '../createCompetition/CompetitionForm';
import ImageUploadField from '../ImageUploadField';
import uploadIcon from '../../assets/uploadIcon.png';
import FileUploadField from '../FileUploadField';
import StyledButton from '../../atoms/common/StyledButton';

const ProfileProjects = () => {
  const { id } = useParams<{ id: string }>();
  const [projects, setProjects] = useState<ProfileProject[]>([]);
  const [showAddProject, setShowAddProject] = useState(false);
  const fetchProjects = async () => {
    const url =
      window.location.pathname.split('/')[1] === 'your-work'
        ? `${process.env.REACT_APP_API_URL}/projects/my-projects`
        : `${process.env.REACT_APP_API_URL}/projects/public/${id}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setProjects(data);
      } else {
        console.log('Error fetching submissions');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleDeleteProject = (id: number) => {
    setProjects(projects.filter((project) => project.id !== id));
  };

  const deleteCallback = useCallback(handleDeleteProject, [
    handleDeleteProject,
  ]);

  const handleChangePrivacy = (id: number) => {
    setProjects(
      projects.map((project) =>
        project.id === id
          ? { ...project, isHidden: !project.isHidden }
          : project
      )
    );
  };

  const changePrivacyCallback = useCallback(handleChangePrivacy, [
    handleChangePrivacy,
  ]);

  const addProject = (project: ProfileProject) => {
    setProjects([...projects, project]);
  };
  const addProjectCallback = useCallback(addProject, [addProject]);

  const [projectId, setProjectId] = useState<number>(0);
  const [editProject, setEditProject] = useState<boolean>(false);
  return (
    <div>
      <div className="grid grid-cols-1 gap-4 p-4 w900:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        {projects.map((project) => (
          <ProjectCard
            key={project.id}
            project={project}
            deleteCallback={deleteCallback}
            changePrivacyCallback={changePrivacyCallback}
            editProject={(id: number) => {
              setProjectId(id);
              setEditProject(true);
              setShowAddProject(true);
            }}
          />
        ))}
      </div>
      {window.location.pathname.split('/')[1] === 'your-work' && (
        <div className="flex w-full flex-col items-center gap-3">
          <i
            className="pi pi-plus-circle cursor-pointer text-7xl text-gray-600"
            onClick={() => setShowAddProject(true)}
          ></i>
          <h2>share your new project</h2>
        </div>
      )}

      <AddProject
        showAddProject={showAddProject}
        setShowAddProject={setShowAddProject}
        addProjectCallback={addProjectCallback}
        edit={editProject}
        id={projectId}
      />
    </div>
  );
};

const ProjectCard = ({
  project,
  deleteCallback,
  changePrivacyCallback,
  editProject,
}: {
  project: ProfileProject;
  deleteCallback: (id: number) => void;
  changePrivacyCallback: (id: number) => void;
  editProject: (id: number) => void;
}) => {
  const [upvoteCount, setUpvoteCount] = useState(project._count.projectVotes);
  const [hasUpvoted, setHasUpvoted] = useState(project.upvotedByMe);

  const toggleVote = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projects/${project.id}/toggle-upvote`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        if (hasUpvoted) {
          setUpvoteCount(upvoteCount - 1);
        } else {
          setUpvoteCount(upvoteCount + 1);
        }
        setHasUpvoted(!hasUpvoted);
      } else {
        console.log('Error toggling vote');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      onClick={() => (window.location.href = `/project/${project.id}`)}
      className="mx-auto w-[320px] rounded-xl border"
    >
      <div className="flex items-center gap-2 p-2">
        <div>
          {project.medal_type === 'GOLD' && (
            <img src={goldMedal} alt="gold medal" className="w-[30px]" />
          )}
          {project.medal_type === 'SILVER' && (
            <img src={silverMedal} alt="silver medal" className="w-[30px]" />
          )}
          {project.medal_type === 'BRONZE' && (
            <img src={bronzeMedal} alt="bronze medal" className="w-[30px]" />
          )}
        </div>
        <h3 className="font-bold">{project.title}</h3>
      </div>
      <ImageComponent
        src={project.cover_image}
        imageName={project.title}
        className="w-full !rounded-none shadow-none"
      />

      <div className="flex items-center justify-between p-2">
        <ButtonIcon
          icon={hasUpvoted ? 'pi pi-sort-up-fill' : 'pi pi-sort-up'}
          className={'rounded-full border border-primary-text px-2 py-1'}
          label={`${upvoteCount}`}
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            toggleVote();
          }}
        />
        {window.location.pathname.split('/')[1] === 'your-work' && (
          <ProjectMenu
            type="project"
            editProject={() => editProject(project.id)}
            isRunning={false}
            submissionId={project.id}
            competitionId={0}
            deleteCallback={deleteCallback}
            changePrivacyCallback={changePrivacyCallback}
            is_private={project.isHidden}
          />
        )}
      </div>
    </div>
  );
};

const AddProject = ({
  showAddProject,
  setShowAddProject,
  addProjectCallback,
  edit,
  id,
}: {
  showAddProject: boolean;
  setShowAddProject: (showAddProject: boolean) => void;
  addProjectCallback: (project: ProfileProject) => void;
  edit: boolean;
  id: number;
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState<'PERSONAL' | 'WORK' | 'OTHER'>('PERSONAL');
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);

  const fetchTags = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/tags`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTags(
          data.map((tag: Tag) => {
            return {
              tag_id: tag.tag_id,
              tag_name: tag.tag_name,
              selected: false,
            };
          })
        );
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const [project, setProject] = useState<ProfileProject>();
  const fetchProject = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projects/${id}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setProject(data);
      } else {
        console.error('Error fetching submission:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching submission:', error);
    }
  };

  useEffect(() => {
    if (edit) fetchProject();
  }, [edit]);

  const urlToFile = async (url: string, fileName: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
  };

  const convertCoverAndFiles = async (
    coverUrl: string,
    filesUrls: string[]
  ): Promise<{ coverFile: File; files: File[] }> => {
    const coverFile = await urlToFile(coverUrl, coverUrl.split('/').pop()!);

    const files = await Promise.all(
      filesUrls.map(async (fileUrl, index) => {
        return await urlToFile(fileUrl, fileUrl.split('/').pop()!);
      })
    );

    return { coverFile, files };
  };

  useEffect(() => {
    if (project) {
      setTitle(project.title);
      setDescription(project.description);
      setType(project.type);
      convertCoverAndFiles(project.cover_image, project.files).then(
        ({ coverFile, files }) => {
          setCoverImage(coverFile);
          setFiles(files);
        }
      );
      setTags(
        tags.map((tag) => {
          return {
            ...tag,
            selected: project.tags.some(
              (projectTag) => projectTag.tag_id === tag.tag_id
            ),
          };
        })
      );
    }
  }, [project]);

  const [buttonClicked, setButtonClicked] = useState(false);

  const handleSubmit = async () => {
    setButtonClicked(true);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('type', type);
    formData.append('cover_image', coverImage as File);
    files.forEach((file) => {
      formData.append('files', file);
    });
    tags
      .filter((tag) => tag.selected)
      .map((tag) => tag.tag_id)
      .forEach((tagId) => {
        formData.append('tags', tagId.toString());
      });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/projects`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            contentType: 'multipart/form-data',
          },
          body: formData,
        }
      );
      if (response.ok) {
        setShowAddProject(false);
        setButtonClicked(false);
        const data = await response.json();
        addProjectCallback(data);
      } else {
        const data = await response.json();
        console.error(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog
      header="Add Project"
      visible={showAddProject}
      style={{
        maxWidth: '600px',
        width: 'fit-content',
        padding: '.2rem',
      }}
      onHide={() => {
        setShowAddProject(false);
      }}
      draggable={false}
      onClick={(e) => e.stopPropagation()}
    >
      <CompetitionFormField
        id="title"
        label="Title"
        value={title}
        onChange={(value) => setTitle(value)}
        placeholder="Enter a descriptive title"
        maxLength={50}
      />
      <CompetitionFormField
        id="description"
        label="Description"
        type="textArea"
        value={description}
        onChange={(value) => setDescription(value)}
        placeholder="Enter a description explaining what competitors will do."
        maxLength={225}
      />
      <select
        value={type}
        onChange={(e) =>
          setType(e.target.value as 'PERSONAL' | 'WORK' | 'OTHER')
        }
        className="w-full rounded-md border border-gray-300 p-2"
      >
        <option value="PERSONAL">Personal</option>
        <option value="WORK">Work</option>
        <option value="OTHER">Other</option>
      </select>
      <h1 className="my-2 text-start text-lg font-bold sm:text-xl">
        Upload Cover Image
      </h1>
      <ImageUploadField
        accept={{ 'image/*': ['.jpeg', '.png'] }}
        image={coverImage}
        setImage={setCoverImage} // Set cover image
        maxSize={5 * 1024 * 1024} // 5 MB
        icon={uploadIcon}
      />
      <h1 className="my-2 text-start text-lg font-bold sm:text-xl">
        Upload Additional Files
      </h1>
      <FileUploadField
        accept={{
          'image/*': ['.jpeg', '.png', '.gif'],
          'application/pdf': ['.pdf'],
        }}
        multiple
        files={files}
        setFiles={setFiles}
        maxSize={5 * 1024 * 1024} // 5 MB per file
        icon={uploadIcon}
      />
      <TagSelection tags={tags} setTags={(tags) => setTags(tags)} />
      <StyledButton
        label={buttonClicked ? 'Adding...' : 'Add Project'}
        disabled={
          buttonClicked ||
          !title ||
          !description ||
          !type ||
          !coverImage ||
          tags.filter((tag) => tag.selected).length === 0
        }
        onClick={handleSubmit}
        className={` ${buttonClicked ? 'animate-pulse bg-gray-400' : ''}`}
      />
    </Dialog>
  );
};

export default ProfileProjects;
