import MainLayout from '../components/MainLayout';

import goldMedal from '../assets/goldMedal.svg';
import silverMedal from '../assets/silverMedal.svg';
import bronzeMedal from '../assets/bronzeMedal.svg';
import novice from '../assets/novice.svg';
import contributor from '../assets/contributor.svg';
import expert from '../assets/expert.svg';
import master from '../assets/master.svg';
import grandMaster from '../assets/grandMaster.svg';

import { Progress } from '../types';
import { useEffect, useState } from 'react';
import ProtectedPage from './ProtectedPage';

interface MedalColumnEntry {
  title: string;
  checked: boolean;
}
interface MedalField {
  colNames: string[];
  states: {
    col: MedalColumnEntry[];
  }[];
}

const Progression = () => {
  const [progress, setProgress] = useState<{
    Novice: MedalField;
    contributor: MedalField;
    expert: MedalField;
    master: MedalField;
    grandMaster: MedalField;
  }>();

  const mapProgress = (progress: Progress) => {
    return {
      Novice: {
        colNames: [''],
        states: [
          {
            col: [
              {
                title: 'Register!',
                checked: progress.Novice,
              },
            ],
          },
        ],
      },
      contributor: {
        colNames: [''],
        states: [
          {
            col: [
              {
                title: 'Make 1 competition submission',
                checked: progress.Contributor.madeSubmission,
              },
              {
                title: 'Make 2 comments',
                checked: progress.Contributor.madeTwoComments,
              },
              {
                title: 'Make 2 replies',
                checked: progress.Contributor.madeTwoReplies,
              },
              {
                title: 'Give 5 votes',
                checked: progress.Contributor.madeFiveVotes,
              },
            ],
          },
        ],
      },
      expert: {
        colNames: ['Competitions', 'Discussions'],
        states: [
          {
            col: [
              {
                title: '2 silvers medals',
                checked: progress.Expert.Competitions.gotTwoSilverMedals,
              },
              {
                title: '1 bronze medals',
                checked: progress.Expert.Competitions.gotOneBronzeMedal,
              },
            ],
          },
          {
            col: [
              {
                title: '5 silvers medals',
                checked: progress.Expert.Discussions.gotFiveSilverMedals,
              },
              {
                title: '20 bronze medals',
                checked: progress.Expert.Discussions.gotTwentyBronzeMedals,
              },
            ],
          },
        ],
      },
      master: {
        colNames: ['Competitions', 'Discussions'],
        states: [
          {
            col: [
              {
                title: '2 gold medals',
                checked: progress.Master.Competitions.gotTwoGoldMedals,
              },
              {
                title: '1 bronze medals',
                checked: progress.Master.Competitions.gotOneBronzeMedal,
              },
            ],
          },
          {
            col: [
              {
                title: '5 gold medals',
                checked: progress.Master.Discussions.gotFiveGoldMedals,
              },
              {
                title: '50 bronze medals',
                checked: progress.Master.Discussions.gotFiftyBronzeMedals,
              },
            ],
          },
        ],
      },
      grandMaster: {
        colNames: ['Competitions', 'Discussions'],
        states: [
          {
            col: [
              {
                title: '10 gold medals',
                checked: progress.GrandMaster.Competitions.gotTenGoldMedals,
              },
            ],
          },
          {
            col: [
              {
                title: '50 gold medals',
                checked: progress.GrandMaster.Discussions.gotFiftyGoldMedals,
              },
            ],
          },
        ],
      },
    };
  };

  const fetchProgress = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/progression`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch progress');
      }
      const data = await response.json();
      setProgress(mapProgress(data));
    } catch (error) {
      console.error('Error fetching progress', error);
    }
  };

  useEffect(() => {
    fetchProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableStyle = {
    tr: 'bg-gray-100',
    td: 'border border-gray-300 px-4 py-2 text-left',
    table: 'min-w-full table-auto border-collapse border border-gray-200',
  };
  return (
    <ProtectedPage>
      <MainLayout>
        <div className="my-24 lg:mx-8 lg:my-10">
          <header className="mb-4 flex flex-col justify-between gap-3 lg:gap-4">
            <h1 className="text-left text-4xl font-bold">Progression System</h1>
            <p className="lg:text-md text-left text-xs text-secondary-text md:w-96">
              Bidayya Progression System uses performance tiers to track your
              growth as a designer on Bidayya. Along the way, you’ll earn medals
              for your achievements and compete for design glory on live
              leaderboards.
            </p>
          </header>
          <hr className="border-1" />
          <div className="mt-10 flex flex-col gap-5">
            <div>
              <h2 className="mb-2 text-left text-xs font-bold text-secondary-text lg:text-xl">
                Categories of Expertise
              </h2>
              <p className="lg:text-md text-left text-xs text-secondary-text">
                The Progression System is designed around two Bidayya categories
                of design expertise: Competitions, Discussion. Advancement
                through performance tiers is done independently within each
                category of expertise.
              </p>
            </div>
            <div className="flex flex-col gap-3 md:w-[800px]">
              <h2 className="text-left text-xs font-bold text-secondary-text lg:text-xl">
                Performance Tiers
              </h2>
              <p className="lg:text-md text-left text-xs text-secondary-text">
                Within each category of expertise, there are five performance
                tiers that can be achieved in accordance with the quality and
                quantity of work you produce: Novice, Contributor, Expert,
                Master, and Grandmaster.
              </p>
              <div className="flex w-full items-center justify-start gap-6">
                <img src={goldMedal} alt="Gold Medal" className="h-20 w-20" />
                <img
                  src={silverMedal}
                  alt="Silver Medal"
                  className="h-20 w-20"
                />
                <img
                  src={bronzeMedal}
                  alt="Bronze Medal"
                  className="h-20 w-20"
                />
              </div>
              <p className="lg:text-md text-left text-xs text-secondary-text">
                The highest tier you have achieved in any of the categories of
                expertise will be displayed on your profile and under your
                avatar across the site. Tiers are awarded on the basis of medals
                earned in each category.
              </p>
            </div>
            <hr className="border-1" />
            <TitleField
              title="Novice"
              description="You’ve joined the community"
              logo={novice}
              logoClassName="w-6"
              colNames={['']}
              states={progress?.Novice.states}
            />

            <hr className="border-1" />
            <TitleField
              title="Contributor"
              description="You’ve completed your profile, engaged with the community, and fully explored Bidayya’s platform."
              colNames={['']}
              logo={contributor}
              logoClassName="w-6"
              states={progress?.contributor.states}
            />

            <hr className="border-1" />
            <TitleField
              title="Expert"
              description="You’ve completed a significant body of work on Bidayya in one or more categories of expertise. Once you’ve reached the expert tier for a category, you will be entered into the site wide Bidayya Ranking for that category."
              colNames={['Competitions', 'Discussions']}
              logo={expert}
              logoClassName="w-12"
              states={progress?.expert.states}
            />
            <hr className="border-1" />
            <TitleField
              title="Master"
              description="You’ve demonstrated excellence in one or more categories of expertise on Kaggle to reach this prestigious tier. Masters in the Competitions category are eligible for exclusive Master-Only competitions."
              colNames={['Competitions', 'Discussions']}
              logo={master}
              logoClassName="w-12"
              states={progress?.master.states}
            />
            <hr className="border-1" />
            <TitleField
              title="Grandmaster"
              description="You’ve consistently demonstrated outstanding performance in one or more categories of expertise on Kaggle to reach this pinnacle tier. You’re the best of the best."
              colNames={['Competitions', 'Discussions']}
              logo={grandMaster}
              logoClassName="w-12"
              states={progress?.grandMaster.states}
            />
            <hr className="border-1" />
            <div>
              <h2 className="mb-2 text-left text-xs font-bold text-secondary-text lg:text-xl">
                Medals
              </h2>
              <h3 className="mb-2 text-left text-xs font-bold text-gray-400 lg:text-2xl">
                To reward your best work
              </h3>
              <p className="lg:text-md text-left text-xs text-secondary-text">
                Medals are a standardized way of recognizing and rewarding
                excellent pieces of work across the categories of expertise on
                Kaggle. Each medal is awarded for a single accomplishment: a
                great competition result, a popular notebook, a useful dataset
                or an insightful comment.
              </p>
            </div>
            <hr className="border-1" />
            <div className="flex justify-start gap-3">
              <div className="flex w-20 flex-col items-center justify-start gap-2">
                <img src={goldMedal} alt="Gold Medal" className="h-10 w-10" />
                <img
                  src={silverMedal}
                  alt="Silver Medal"
                  className="h-10 w-10"
                />
                <img
                  src={bronzeMedal}
                  alt="Bronze Medal"
                  className="h-10 w-10"
                />
              </div>
              <div>
                <h2 className="mb-2 text-left text-xs font-bold text-secondary-text lg:text-xl">
                  Competition Medals
                </h2>
                <p className="lg:text-md text-left text-xs text-secondary-text">
                  All applications will be verified and validated by a community
                  of professionals before being shared with the company hosting
                  the competition. All submissions shared with the business are
                  considered successful projects, regardless of whether they
                  receive a gold medal or not. The gold medal is a sign of
                  preference, not a sign of excellence
                </p>
              </div>
            </div>
            <hr className="border-1" />
            <div className="flex justify-start gap-3">
              <div className="flex w-20 flex-col items-center justify-start gap-2">
                <img src={goldMedal} alt="Gold Medal" className="h-10 w-10" />
                <img
                  src={silverMedal}
                  alt="Silver Medal"
                  className="h-10 w-10"
                />
                <img
                  src={bronzeMedal}
                  alt="Bronze Medal"
                  className="h-10 w-10"
                />
              </div>
              <div>
                <h2 className="mb-2 text-left text-xs font-bold text-secondary-text lg:text-xl">
                  Discussion Medals
                </h2>
                <p className="lg:text-md text-left text-xs text-secondary-text">
                  Discussion Medals are awarded to popular topics and comments
                  posted across the site, as measured by net votes (upvotes
                  minus downvotes). Not all upvotes count towards medals: votes
                  by novices and votes on old posts are excluded from medal
                  calculation, and there are additional hidden rules as well, to
                  prevent upvote rings and progression system manipulation.
                </p>
                <div className="mt-5 flex w-full items-center justify-center">
                  <div>
                    <table className={tableStyle.table}>
                      <tr className={tableStyle.tr}>
                        <td className={tableStyle.td}>Bronze</td>
                        <td className={tableStyle.td}>1 vote</td>
                      </tr>
                      <tr className={tableStyle.tr}>
                        <td className={tableStyle.td}>Silver</td>
                        <td className={tableStyle.td}>5 votes</td>
                      </tr>
                      <tr className={tableStyle.tr}>
                        <td className={tableStyle.td}>Gold</td>
                        <td className={tableStyle.td}>30 votes</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <hr className="border-1" />
            <div>
              <h2 className="mb-2 text-left text-xs font-bold text-secondary-text lg:text-xl">
                Bidayya Rankings and Points
              </h2>
              <h3 className="mb-2 text-left text-xs font-bold text-gray-400 lg:text-xl">
                To show where you stand
              </h3>
            </div>
          </div>
        </div>
      </MainLayout>
    </ProtectedPage>
  );
};

const StateField = ({
  title,
  checked,
}: {
  title: string;
  checked: boolean;
}) => {
  return (
    <div className="flex items-center justify-start gap-2">
      {checked ? (
        <i className="pi pi-check-square text-light-blue"></i>
      ) : (
        <i className="pi pi-stop"></i>
      )}
      <span className="text-xs text-secondary-text">{title}</span>
    </div>
  );
};

const TitleField = ({
  title,
  description,
  colNames,
  logo,
  logoClassName,
  states,
}: {
  title: string;
  description: string;
  colNames: string[];
  logo?: string;
  logoClassName?: string;
  states?: {
    col: MedalColumnEntry[];
  }[];
}) => {
  return (
    <div className="flex items-center justify-start gap-[20px] md:w-[800px]">
      <div className="flex !w-[60px] items-center justify-center">
        <img src={logo} alt={title} className={logoClassName} />
      </div>
      <div className="flex w-full flex-col gap-1 text-start">
        <h2 className="text-left text-xs font-bold text-secondary-text lg:text-xl">
          {title}
        </h2>
        <p className="lg:text-md text-left text-xs text-secondary-text">
          {description}
        </p>
        <div className="flex items-center justify-start gap-3">
          {colNames.map((colName, index) => (
            <div className="flex flex-col gap-1">
              <h3 className="lg:text-md text-left text-xs font-semibold text-secondary-text">
                {colName}
              </h3>
              {states &&
                states[index].col.map((t) => (
                  <StateField title={t.title} checked={t.checked} />
                ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Progression;
