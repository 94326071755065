import { Outlet, useParams } from 'react-router-dom';
import MainLayout from '../components/MainLayout';
import Header from '../components/yourwork/Header';
import { ProfileHeader } from '../types';
import { useEffect, useState } from 'react';
import ProtectedPage from './ProtectedPage';

const Profile = () => {
  const { id } = useParams();
  const [profile, setProfile] = useState<ProfileHeader>();
  const fetchProfile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${id}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setProfile(data);
      } else {
        console.log('Error fetching replies');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);
  return (
    <ProtectedPage>
      <MainLayout>
        <div className="mx-0 my-24 lg:mx-4 lg:my-10">
          <header className="mb-4 flex flex-col justify-between gap-3 lg:gap-4">
            {profile && <Header {...profile} id={Number(id)} />}
          </header>
          <Outlet />
        </div>
      </MainLayout>
    </ProtectedPage>
  );
};

export default Profile;
