import { Tag } from './Tag';

export type CompetitionDetails = {
  id: number;
  name: string;
  competition_url: string;
  description: string;
  overview: string;
  company_id: number;
  price: number;
  rules: string;
  host_id: number;
  files: string[];
  cover_image: string;
  submission_deadline: string;
  created_at: string;
  is_private: boolean;
  state: CompetitionState;
  submission_id_winner: null | number;
  access_type: CompetitionAccessType;
  host: CompetitionHost;
  host_username: string;
  company: CompetitionCompany;
  tags: Tag[];
  joinedUsers: JoinedUser[];
  participations: number;
  bookmarkedByMe: boolean;
  is_joined: boolean;
  is_submitted: boolean;
  mySubmissionId: number;
};

export enum CompetitionState {
  NEW = 'NEW',
  UNDER_REVIEW = 'UNDER_REVIEW',
  ONGOING = 'ONGOING',
  DECLINED = 'DECLINED',
  CLOSED = 'CLOSED',
  ENDED = 'ENDED',
  RATED = 'RATED',
}

export enum CompetitionAccessType {
  ANYONE = 'ANYONE',
  ONLY_WITH_LINK = 'ONLY_WITH_LINK',
  RESTRICTED = 'RESTRICTED',
}

export interface CompetitionHost {
  full_name: string;
  email: string;
  job_title: string;
  profile_image: string;
  username: string;
}

export interface CompetitionCompany {
  company_id: number;
  company_email: string;
  company_name: string;
  user_id: number;
}

export interface CompetitionTag {
  competition_id: number;
  tag_id: number;
  tag: Tag;
}

export interface JoinedUser {
  user_id: number;
}
