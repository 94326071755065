import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import binIcon from '../assets/binIcon.png';
import SkeletonImage from '../atoms/common/SkeltonImage';
import { Image } from 'antd';

interface ImageUploadFieldProps {
  accept: { [key: string]: string[] };
  image: File | null;
  setImage: any;
  maxSize?: number;
  icon?: string;
}

interface FileWithStatus {
  file: File;
  status: 'uploading' | 'uploaded';
  preview?: string;
}

const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
  accept,
  image,
  setImage,
  maxSize,
  icon,
}) => {
  const [fileStatuses, setFileStatuses] = useState<FileWithStatus[]>([]);
  const [preview, setPreview] = useState<string | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;
      const newFile = acceptedFiles[0]; // Single file

      // Create preview URL
      const previewUrl = URL.createObjectURL(newFile);
      setPreview(previewUrl);

      const newFileStatus = {
        file: newFile,
        status: 'uploading' as const,
        preview: previewUrl,
      };

      setImage(newFile); // Set the cover image
      setFileStatuses([newFileStatus]);

      // Simulate file upload and change status to "uploaded"
      setTimeout(() => {
        setFileStatuses([{ ...newFileStatus, status: 'uploaded' }]);
      }, 2000);
    },
    [setImage]
  );

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      accept,
      maxSize,
      multiple: false, // Single image
    });

  const removeFile = () => {
    setImage(null); // Remove image
    setFileStatuses([]);
    setPreview(null); // Remove preview
  };

  const fileSizeInMB = (size: number) => (size / (1024 * 1024)).toFixed(2);

  useEffect(() => {
    if (image) {
      const previewUrl = URL.createObjectURL(image);
      setPreview(previewUrl);
      setFileStatuses([
        { file: image, status: 'uploaded', preview: previewUrl },
      ]);
    }
  }, [image]);

  // Clean up object URL on component unmount
  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  return (
    <div className="mb-4 max-w-[450px]">
      {!preview && (
        <div
          {...getRootProps()}
          className={`flex flex-col items-center justify-center gap-4 rounded-md border-2 border-dashed p-6 ${
            isDragActive ? 'border-regal-blue animate-pulse' : 'border-gray-300'
          }`}
        >
          <input {...getInputProps()} />
          {icon && (
            <img
              src={icon}
              alt="upload icon"
              className="mx-auto w-2"
              loading="lazy"
            />
          )}
          {isDragActive ? (
            <p className="text-regal-blue">Drop the files here ...</p>
          ) : (
            <p className="font-semibold text-gray-700">
              Drag & drop an image or{' '}
              <span className="text-bold text-regal-blue underline underline-offset-2">
                Browse
              </span>
            </p>
          )}
          <p className="text-xs text-gray-700">Supported formats: JPEG, PNG</p>
        </div>
      )}
      {fileRejections.length > 0 && (
        <div className="mt-2 text-sm text-red-500">
          {fileRejections.map(({ file, errors }, idx) => (
            <div key={idx}>
              {errors.map((e) => (
                <p key={e.code}>
                  {e.message.replace(' bytes', '').replace(/\d+/g, (match) => {
                    // Convert MB to bytes for better readability
                    return fileSizeInMB(maxSize || 0) + ' MB';
                  })}
                </p>
              ))}
            </div>
          ))}
        </div>
      )}

      {fileStatuses.some(({ status }) => status === 'uploading') && (
        <p className="mt-4 text-start font-bold text-gray-700">Uploading...</p>
      )}

      {preview && (
        <div className="mt-4 flex flex-col items-center">
          <Image
            src={preview}
            alt="Preview"
            width="100%"
            height="100%"
            className="rounded-md object-cover"
          />
        </div>
      )}

      <ul className="mt-2">
        {fileStatuses.map(({ file, status }, idx) => (
          <li
            key={idx}
            className={`my-2 flex flex-row items-center justify-between rounded-md px-3 py-2 ${
              status === 'uploading'
                ? 'border-regal-blue animate-pulse border border-dashed bg-blue-100'
                : 'border-regal-blue border'
            }`}
          >
            <span className="w-full text-xs">
              <span className="break-words"> {file?.name}</span>-{' '}
              {fileSizeInMB(file?.size)} MB
            </span>
            <button
              type="button"
              onClick={removeFile}
              className="rounded-full bg-red-50 p-1 hover:bg-red-200"
            >
              <img src={binIcon} alt="Bin Icon" width="10px" loading="lazy" />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ImageUploadField;
