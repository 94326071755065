import { useEffect, useState } from 'react';
import { DiscussionSummary, ProfileComment, ProfileTopic } from '../../types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import DetailedSummaryCard from './DetailedSummaryCard';
import comment_topics from '../../assets/comment_topic.svg';
import { timeAgo } from '../../utils';
import { Link } from 'react-router-dom';
import ButtonIcon from '../../atoms/common/ButtonIcon';

const ProfileDiscussions = () => {
  const { id } = useParams<{ id: string }>();
  const user = useSelector((state: RootState) => state.user.user);

  const finalId =
    window.location.pathname.split('/')[1] === 'your-work' ? user.id : id;

  const [discussionSummary, setDiscussionSummary] =
    useState<DiscussionSummary>();

  const fetchDiscussionSummary = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${finalId}/discussions-summary`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setDiscussionSummary(data);
      } else {
        console.log('Error fetching discussion summary');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDiscussionSummary();
  }, []);

  const [tab, setTab] = useState<'topics' | 'comments' | 'both'>('both');

  const [topics, setTopics] = useState<ProfileTopic[]>([]);
  const [comments, setComments] = useState<ProfileComment[]>([]);
  const [both, setBoth] = useState<ProfileTopic[] | ProfileComment[]>([]);

  const fetchTopics = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${finalId}/topics`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setTopics(data);
        console.log(data);
      } else {
        console.log('Error fetching topics');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchComments = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${finalId}/comments`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setComments(data);
        console.log(data);
      } else {
        console.log('Error fetching comments');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchBoth = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${finalId}/topics-with-comments`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setBoth(data);
        console.log(data);
      } else {
        console.log('Error fetching both');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (tab === 'topics') {
      fetchTopics();
    } else if (tab === 'comments') {
      fetchComments();
    } else {
      fetchBoth();
    }
  }, [tab]);
  return (
    <div>
      {discussionSummary && (
        <DetailedSummaryCard
          title="Discussion"
          goldCount={discussionSummary?.discussion_gold_medals}
          silverCount={discussionSummary?.discussion_silver_medals}
          bronzeCount={discussionSummary?.discussion_bronze_medals}
          level={discussionSummary?.discussion_level}
          rank={discussionSummary?.discussion_rank}
          scorePoints={discussionSummary?.discussion_score_points}
          highestRank={discussionSummary?.highest_discussion_rank}
          counts={[
            {
              title: 'Topics',
              count: discussionSummary?._count.topics,
            },
            {
              title: 'Comments',
              count: discussionSummary?._count.comments,
            },
          ]}
          totalUsersCount={discussionSummary.totalNumberofUsers}
        />
      )}

      <div className="my-5 flex w-full gap-5 border-b">
        <button
          onClick={() => setTab('both')}
          className={`${
            tab === 'both' ? 'border-b-2 border-black font-bold' : ''
          }`}
        >
          All
        </button>
        <button
          onClick={() => setTab('topics')}
          className={`${
            tab === 'topics' ? 'border-b-2 border-black font-bold' : ''
          }`}
        >
          Topics
        </button>
        <button
          onClick={() => setTab('comments')}
          className={`${
            tab === 'comments' ? 'border-b-2 border-black font-bold' : ''
          }`}
        >
          Comments
        </button>
      </div>

      <div>
        {tab === 'both' &&
          both.map((data) => <CardParser data={data} key={data.id} />)}
        {tab === 'topics' &&
          topics.map((topic) => <TopicCard topic={topic} key={topic.id} />)}
        {tab === 'comments' &&
          comments.map((comment) => (
            <CommentCard comment={comment} key={comment.id} />
          ))}
      </div>
    </div>
  );
};

const CommentCard = ({ comment }: { comment: ProfileComment }) => {
  const [upvoteCount, setUpvoteCount] = useState(comment._count.commentVotes);
  const [hasUpvoted, setHasUpvoted] = useState(comment.upvotedByMe);

  const toggleVote = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/comments/${comment.id}/toggle-upvote`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        setHasUpvoted(!hasUpvoted);
        setUpvoteCount(hasUpvoted ? upvoteCount - 1 : upvoteCount + 1);
      } else {
        console.log('Error toggling vote');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex w-full justify-between gap-5 border-b p-3">
      <div className="flex w-full gap-5 p-3">
        <div className="h-fit w-fit rounded-full border p-3">
          <img
            src={comment_topics}
            alt="comment"
            className="aspect-square w-[35px]"
          ></img>
        </div>
        <div className="flex flex-col gap-1 text-start">
          <h2 className="text-xl font-semibold">
            {comment.type === 'reply' ? 'Reply' : 'Comment'} on{' '}
            {comment.competition.name}
          </h2>
          <p className="flex gap-1">
            Posted {timeAgo(new Date(comment.created_at))} in
            <Link
              className="underline"
              to={`/competitions/${comment.competition.id}`}
            >
              {comment.competition.name}
            </Link>
          </p>
          <p>{comment.content}</p>
          {comment.type === 'comment' && (
            <Link
              to={`/competitions/${comment.competition.id}/discussions/${comment.parent_topic?.id}`}
              className="text-primary-color underline"
            >
              View Topic
            </Link>
          )}
        </div>
      </div>
      <ButtonIcon
        icon={hasUpvoted ? 'pi pi-sort-up-fill' : 'pi pi-sort-up'}
        className="rounded-full border border-primary-text px-2 py-1"
        label={`${upvoteCount}`}
        onClick={toggleVote}
      />
    </div>
  );
};
const TopicCard = ({ topic }: { topic: ProfileTopic }) => {
  const [upvoteCount, setUpvoteCount] = useState(topic._count.topicVotes);
  const [hasUpvoted, setHasUpvoted] = useState(topic.upvotedByMe);

  const toggleVote = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/topics/${topic.id}/toggle-upvote`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        setHasUpvoted(!hasUpvoted);
        setUpvoteCount(hasUpvoted ? upvoteCount - 1 : upvoteCount + 1);
      } else {
        console.log('Error toggling vote');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex w-full justify-between gap-5 border-b p-3">
      <div className="flex w-full gap-5 p-3">
        <div className="h-fit w-fit rounded-full border p-3">
          <img
            src={comment_topics}
            alt="topic"
            className="aspect-square w-[35px]"
          ></img>
        </div>
        <div className="flex flex-col gap-1 text-start">
          <h2 className="text-xl font-semibold">{topic.name}</h2>
          <p className="flex gap-1">
            Topic . Posted {timeAgo(new Date(topic.created_at))} in
            <Link
              className="underline"
              to={`/competitions/${topic.competition.id}`}
            >
              {topic.competition.name}
            </Link>
          </p>
          <p>{topic.content}</p>
          <Link
            to={`/competitions/${topic.competition.id}/discussions/${topic.id}`}
            className="text-primary-color underline"
          >
            View Topic
          </Link>
        </div>
      </div>
      <ButtonIcon
        icon={hasUpvoted ? 'pi pi-sort-up-fill' : 'pi pi-sort-up'}
        className="rounded-full border border-primary-text px-2 py-1"
        label={`${upvoteCount}`}
        onClick={toggleVote}
      />
    </div>
  );
};
const CardParser = ({ data }: { data: ProfileTopic | ProfileComment }) => {
  if (!data.hasOwnProperty('type')) {
    return <TopicCard topic={data as ProfileTopic} />;
  } else if ((data as ProfileComment).type === 'topic') {
    return <TopicCard topic={data as ProfileTopic} />;
  }
  return <CommentCard comment={data as ProfileComment} />;
};

export default ProfileDiscussions;
