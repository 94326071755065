import { MenuProps } from 'antd';

export type CompetitionCreate = {
  id?: string;
  competitionName: string;
  description: string;
  companyName: string;
  price: number;
  rules: string | undefined;
  overview: string;
  competitionUrl: string;
  submissionDeadline: string;
  isPrivate: boolean;
  accessType: 'ANYONE' | 'ONLY_WITH_LINK' | 'RESTRICTED';
  files: File[];
  coverImage: File | null;
  tags: Tag[];
  joinAvailability: string;
  termsAccepted: boolean;
  state?: string;
};

export type CompetitionDetails = {
  id: number;
  name: string;
  competition_url: string;
  description: string;
  company_id: number;
  price: number;
  rules: string;
  overview: string;
  host_id: number;
  files: string[];
  cover_image: string;
  submission_deadline: string;
  created_at: string;
  state:
    | 'NEW'
    | 'UNDER_REVIEW'
    | 'ONGOING'
    | 'DECLINED'
    | 'CLOSED'
    | 'ENDED'
    | 'RATED';
  is_active: boolean;
  is_private: boolean;
  submission_id_winner: null | number;
  access_type: 'ANYONE' | 'ONLY_WITH_LINK' | 'RESTRICTED';
  company: {
    company_id: number;
    company_email: string;
    company_name: string;
    user_id: number;
  };
  joinedUsers: [];
  is_joined: boolean;
  is_submitted: boolean;
  participations: number;
  mySubmissionId: number;
};

export type Tag = {
  tag_id: number;
  tag_name: string;
  selected: boolean;
};

export type ActiveCompetition = {
  title: string;
  submissionsLeft: number;
  timeAgo: string;
  rank: string;
  totalParticipants: number;
};

export type ButtonVariant =
  | 'primary'
  | 'primarySmall'
  | 'secondary'
  | 'secondaryOutline'
  | 'danger'
  | 'pdf';

enum Role {
  ADMIN,
  USER,
  EMPLOYEE,
}

export enum ProgressLevel {
  Grandmaster,
  Master,
  Expert,
  Contributor,
  Novice,
}

export interface CountRanking {
  level: ProgressLevel;
  count: number;
}

export interface UserRank {
  [key: string]: any;
  id: number;
  full_name: string;
  competition_bronze_medals: number;
  competition_gold_medals: number;
  competition_silver_medals: number;
  competition_score_points: number;
  created_at: string;
  competition_level: string;
  competition_rank: number;
}

export type User = {
  id: number;
  full_name: String;
  username: String;
  email: String;
  password: String;
  created_at: String;
  deleted_at: String;
  organisation: String;
  country: String;
  competition_gold_medals: number;
  competition_bronze_medals: number;
  competition_silver_medals: number;
  competition_level: ProgressLevel;
  discussion_gold_medals: number;
  discussion_bronze_medals: number;
  discussion_silver_medals: number;
  discussion_level: ProgressLevel;
  competition_points: number;
  discussion_points: number;
  competition_won: String;
  role: Role;
  is_joined_competition: boolean;
};

export type Setting = {
  type: 'email' | 'site';
  isChecked: boolean;
};

export type SectionData = {
  title: string;
  description?: string;
  settings: Setting[];
};

export type SectionDataCollection = {
  notifications: SectionData[];
  alerts: SectionData[];
  discussions: SectionData[];
  comments: SectionData[];
  achievements: SectionData[];
};

export type DashboardProps = {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  hover: boolean;
  setHover: React.Dispatch<React.SetStateAction<boolean>>;
};
export type MenuItem = Required<MenuProps>['items'][number];
