import bidayyaComing from '../../assets/bidayyaComming.svg';
import bidayyaComing2 from '../../assets/bidayyaComing2.svg';

const BidayyaComing = () => {
  return (
    <div className="relative my-20">
      <div className="mx-auto flex max-w-[960px] flex-col gap-5">
        <h2 className="text-3xl font-bold">Here come Bidayya</h2>
        <p className="text-xl">
          the platform revolutionizing the design world by offering unparalleled
          opportunities for designers to grow, showcase, and elevate their
          talents.
        </p>
        <img src={bidayyaComing} alt="personal profile" className="mx-3" />
      </div>
      <div className="mt-20 flex flex-wrap justify-center gap-8">
        <ComingCard
          title="Win Rewards, Gain Recognition"
          description="Compete and excel in design challenges, earn prizes, and climb the community ranks, gaining recognition that propels your career forward."
        />
        <ComingCard
          title="Build Your Legacy: From Day One"
          description="Start crafting your professional portfolio from the very beginning with Bidayya. Every project contributes to your evolving designer profile, showcasing your journey and growth."
        />
        <ComingCard
          title="Feedback that Fuels Your Growth"
          description="Enhance your skills with valuable feedback from industry experts and the Bidayya community, turning every project into a learning opportunity."
        />
        <ComingCard
          title="Build a Recognizable Brand"
          description="Establish your unique brand identity through consistent participation in challenges, making your design style known and admired within the community."
        />
      </div>
      <img
        src={bidayyaComing2}
        className="absolute right-0 top-[55%] -z-50 -translate-y-1/2"
        alt=""
      />
    </div>
  );
};

const ComingCard = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <div className="flex max-w-[330px] flex-col items-center gap-3 p-4">
      <h3 className="text-lg font-bold">{title}</h3>
      <p className="text-center">{description}</p>
    </div>
  );
};

export default BidayyaComing;
