import './App.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DeleteAccountProvider } from './hooks/DeleteAccountContext';
import { ExpandHoverProvider } from './hooks/ExpandHoverContext';
import { CompetitionDetails } from './types/CompetitionDetails';

import { ToastProvider } from './ctx/Toast';

import 'primereact/resources/themes/lara-light-indigo/theme.css'; // Change theme if needed
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css'; // For icons
import 'primereact/resources/primereact.min.css';

import { lazy, Suspense } from 'react';
import Loading from './components/general/Loading';
import EditCompetition from './pages/EditCompetition';

// Pages
const Signup = lazy(() => import('./pages/Signup'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const Signin = lazy(() => import('./pages/Signin'));
const NotFound = lazy(() => import('./pages/NotFound'));
// const Home = lazy(() => import('./pages/Home'));
const Settings = lazy(() => import('./pages/Settings'));
const CreateCompetition = lazy(() => import('./pages/CreateCompetition'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const CompetitionPage = lazy(() => import('./pages/CompetitionPage'));
const Competitions = lazy(() => import('./pages/Competitions'));
const CreateSubmission = lazy(() => import('./pages/CreateSubmission'));
const EditSubmission = lazy(() => import('./pages/EditSubmission'));
const SubmissionPage = lazy(() => import('./pages/SubmissionPage'));
const HostDashboard = lazy(() => import('./pages/HostDashboard'));
const Guidelines = lazy(() => import('./pages/Guidelines'));
const Progression = lazy(() => import('./pages/Progression'));
const DiscussionsPage = lazy(() => import('./pages/DiscussionsPage'));
const YourWork = lazy(() => import('./pages/YourWork'));
const EditProfile = lazy(() => import('./pages/EditProfile'));
const Profile = lazy(() => import('./pages/Profile'));
const ProjectPage = lazy(() => import('./pages/ProjectPage'));
const Landing = lazy(() => import('./pages/Landing'));
const ProtectedPage = lazy(() => import('./pages/ProtectedPage'));
const UserRanking = lazy(() => import('./pages/UserRank'));

// Dashboard Components
const NewCompetitions = lazy(
  () => import('./components/dashboard/NewCompetitions')
);
const ApprovedCompetitions = lazy(
  () => import('./components/dashboard/ApprovedCompetitions')
);
const DeclinedCompetitions = lazy(
  () => import('./components/dashboard/DeclinedCompetitions')
);
const UnderReview = lazy(() => import('./components/dashboard/UnderReview'));
const UsersInformation = lazy(
  () => import('./components/dashboard/UsersInformation')
);
const RunningCompetitionDetails = lazy(
  () => import('./components/dashboard/RunningCompetitionDetails')
);

// Competition Components
const Overview = lazy(() => import('./components/competition/Overview'));
const Submissions = lazy(() => import('./components/competition/Submissions'));
const Discussions = lazy(() => import('./components/competition/Discussions'));
const Leaderboard = lazy(() => import('./components/competition/LeaderBoard'));
const Rules = lazy(() => import('./components/competition/Rules'));

// Settings Components
const AccountSettings = lazy(
  () => import('./components/settings/AccountSettings')
);
const NotificationsSettings = lazy(
  () => import('./components/settings/NotificationsSettings')
);

// Host Dashboard Components
const HNewCompetitions = lazy(
  () => import('./components/hostdashboard/HNewCompetitions')
);
const HUnderReview = lazy(
  () => import('./components/hostdashboard/HUnderReview')
);
const HRunningCompetitions = lazy(
  () => import('./components/hostdashboard/HRunningCompetitions')
);
const HDeclinedCompetitions = lazy(
  () => import('./components/hostdashboard/HDeclinedCompetitions')
);
const HEndedCometitions = lazy(
  () => import('./components/hostdashboard/HEndedCometitions')
);
const HRatedCompetitions = lazy(
  () => import('./components/hostdashboard/HRatedCompetitions')
);

// Your Work Components
const Followers = lazy(() => import('./components/yourwork/Followers'));
const ProfileSubmissions = lazy(
  () => import('./components/yourwork/ProfileSubmissions')
);
const ProfileProjects = lazy(
  () => import('./components/yourwork/ProfileProjects')
);
const ProfileHome = lazy(() => import('./components/yourwork/ProfileHome'));
const ProfileCompetitions = lazy(
  () => import('./components/yourwork/ProfileCompetitions')
);
const ProfileDiscussions = lazy(
  () => import('./components/yourwork/ProfileDiscussions')
);

// Landing Components
const LandingHome = lazy(() => import('./components/landing/LandingHome'));
const LandingHire = lazy(() => import('./components/landing/LandingHire'));

// Other Components
const MainLayout = lazy(() => import('./components/MainLayout'));
const CompetitionDetailsComp = lazy(
  () => import('./atoms/dashboard/CompetitionDetails')
);
const DiscussionDetails = lazy(
  () => import('./atoms/competition/DiscussionDetails')
);

function App() {
  return (
    <ExpandHoverProvider>
      <ToastProvider>
        <div className="App">
          <Suspense fallback={<Loading />}>
            <BrowserRouter>
              <Routes>
                <Route path="/signup" element={<Signup />} />
                <Route path="/signin" element={<Signin />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/settings"
                  element={
                    <DeleteAccountProvider>
                      <Settings />
                    </DeleteAccountProvider>
                  }
                >
                  <Route path="account" element={<AccountSettings />} />
                  <Route
                    path="notifications"
                    element={<NotificationsSettings />}
                  />
                  <Route path="" element={<AccountSettings />} />
                </Route>
                <Route path="/" element={<Landing />}>
                  <Route path="hire" element={<LandingHire />} />
                  <Route path="" element={<LandingHome />} />
                </Route>
                <Route path="/discussions" element={<DiscussionsPage />} />
                <Route
                  path="/create-competition"
                  element={<CreateCompetition />}
                />
                <Route
                  path="/edit-competition/:competitionUrl"
                  element={<EditCompetition />}
                />
                <Route path="/dashboard" element={<Dashboard />}>
                  <Route
                    path=":state/:competitionUrl"
                    element={<CompetitionDetailsComp />}
                  />

                  <Route path="new" element={<NewCompetitions />} />
                  <Route path="approved" element={<ApprovedCompetitions />} />
                  <Route path="declined" element={<DeclinedCompetitions />} />
                  <Route path="under-review" element={<UnderReview />} />
                  <Route
                    path="users-information"
                    element={<UsersInformation />}
                  />
                  <Route path="" element={<NewCompetitions />} />
                </Route>
                <Route path="/host-dashboard" element={<HostDashboard />}>
                  <Route
                    path="running/:competitionUrl"
                    element={<RunningCompetitionDetails />}
                  />
                  <Route
                    path="ended/:competitionUrl"
                    element={<RunningCompetitionDetails />}
                  />

                  <Route
                    path=":state/:competitionUrl"
                    element={<CompetitionDetailsComp />}
                  />
                  {/* <Route path=":state/:id/edit" element={<CreateCompetition />} /> */}
                  <Route path="new" element={<HNewCompetitions />} />
                  <Route path="under-review" element={<HUnderReview />} />
                  <Route path="running" element={<HRunningCompetitions />} />
                  <Route path="declined" element={<HDeclinedCompetitions />} />
                  <Route path="ended" element={<HEndedCometitions />} />
                  <Route path="rated" element={<HRatedCompetitions />} />
                  <Route path="" element={<HNewCompetitions />} />
                </Route>

                <Route path="/competitions">
                  <Route
                    path=":competitionUrl/submit"
                    element={
                      <ProtectedPage>
                        <MainLayout>
                          <CreateSubmission />
                        </MainLayout>
                      </ProtectedPage>
                    }
                  />
                  <Route
                    path=":competitionUrl/submit/:submissionId"
                    element={
                      <ProtectedPage>
                        <MainLayout>
                          <EditSubmission />
                        </MainLayout>
                      </ProtectedPage>
                    }
                  />
                  <Route
                    path=":competitionUrl/submissions/:submissionId"
                    element={<SubmissionPage />}
                  />
                  <Route
                    path=":competitionUrl"
                    element={
                      <MainLayout>
                        <CompetitionPage />
                      </MainLayout>
                    }
                  >
                    <Route path="overview" element={<Overview />} />
                    <Route path="submissions" element={<Submissions />} />
                    <Route path="discussions">
                      <Route
                        path=":discussionId"
                        element={<DiscussionDetails />}
                      />
                      <Route path="" element={<Discussions />} />
                    </Route>
                    <Route path="leaderboard" element={<Leaderboard />} />
                    <Route path="rules" element={<Rules />} />
                    <Route path="" element={<Overview />} />
                  </Route>
                  <Route path="" element={<Competitions />}></Route>
                </Route>
                <Route path="/your-work/edit" element={<EditProfile />} />
                <Route path="/your-work" element={<YourWork />}>
                  <Route path="followers" element={<Followers />} />
                  <Route path="submissions" element={<ProfileSubmissions />} />
                  <Route path="projects" element={<ProfileProjects />} />
                  <Route path="home" element={<ProfileHome />} />
                  <Route
                    path="competitions"
                    element={<ProfileCompetitions />}
                  />
                  <Route path="discussions" element={<ProfileDiscussions />} />
                  <Route path="" element={<ProfileHome />} />
                </Route>
                <Route path="/profile/:profileId" element={<Profile />}>
                  <Route path="followers" element={<Followers />} />
                  <Route path="submissions" element={<ProfileSubmissions />} />
                  <Route path="projects" element={<ProfileProjects />} />
                  <Route path="home" element={<ProfileHome />} />
                  <Route
                    path="competitions"
                    element={<ProfileCompetitions />}
                  />
                  <Route path="discussions" element={<ProfileDiscussions />} />
                  <Route path="" element={<ProfileHome />} />
                </Route>
                <Route path="/project/:projectId" element={<ProjectPage />} />
                <Route path="/community-guidelines" element={<Guidelines />} />
                <Route path="/progression" element={<Progression />} />
                <Route path="/user-ranking" element={<UserRanking />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </Suspense>
        </div>
      </ToastProvider>
    </ExpandHoverProvider>
  );
}

export default App;
