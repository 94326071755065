import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Submission } from '../../types';
import SubmissionCard from '../hostdashboard/SubmissionCard';
import ImageComponent from '../../atoms/common/ImageComponent';
import StyledButton from '../../atoms/common/StyledButton';

const Leaderboard: React.FC = () => {
  const [leaderboard, setLeaderboard] = useState<Submission[]>();
  const [stillOngoing, setStillOngoing] = useState<boolean>(false);

  const [isWinner, setIsWinner] = useState<boolean>(false);
  const [winner, setWinner] = useState<Submission | null>(null);

  const { id } = useParams<{ id: string }>();
  const fetchCompetition = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/${id}/leaderboard`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setLeaderboard(data.leaderboard);
        if (data.winner) {
          setIsWinner(true);
          setWinner(data.winner);
        } else {
          setWinner(null);
          setIsWinner(false);
        }
      } else if (
        response.status === 400 &&
        response.statusText === 'Competition is still ongoing'
      ) {
        setStillOngoing(true);
      } else {
        console.error('Error fetching competition:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching competition:', error);
    }
  };
  useEffect(() => {
    fetchCompetition();
  }, []);
  return (
    <div className="text-start">
      <h3 className="text-xl font-bold">Leaderboard</h3>
      {stillOngoing && (
        <div className="mt-2 flex w-full items-center justify-center rounded-lg border-2 p-2">
          <p className="text-2xl font-bold">Competition is still ongoing</p>
        </div>
      )}
      {winner !== null && (
        <div className="mt-5 flex h-[200px] items-center rounded-xl border-2">
          <ImageComponent
            src={winner.cover_image}
            imageName="Submission cover image"
            className="!h-[200px] !min-h-[200px]"
          />
          <div className="flex flex-grow flex-col items-center justify-start gap-2 p-3">
            <h2 className="text-3xl font-bold">The Winner !</h2>
            <h3 className="text-sm">
              Congrats this is the winner of the competition{' '}
            </h3>
            <p className="font-bold">{winner.user.full_name}</p>
            <StyledButton
              label="Explore Submission"
              variant="primary"
              onClick={() => {
                window.open(
                  `/competitions/${winner?.competition_id}/submissions/${winner?.id}`,
                  '_blank'
                );
              }}
            />
          </div>
        </div>
      )}
      {leaderboard && leaderboard.length > 0 && (
        <div className="mt-5 flex flex-col gap-4">
          {leaderboard.map((submission, index) => (
            <SubmissionCard
              key={submission.id}
              submission={submission}
              deadline={' '}
              selectMedal={() => {}}
              initialSubmission={submission}
              goldCount={0}
              silverCount={0}
              bronzeCount={0}
              goldLimit={0}
              silverLimit={0}
              bronzeLimit={0}
              selectedWinner={winner ? winner.id : null}
              setSelectedWinner={() => {}}
              isWinner={isWinner}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Leaderboard;
