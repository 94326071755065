import React from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import logo from '../../assets/logoWhite.svg';
interface SidebarHeaderProps {
  toggleSidebar: () => void;
  expanded: boolean;
  hover: boolean;
}
const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  toggleSidebar,
  expanded,
  hover,
}) => {
  return (
    <div
      className={`flex items-center justify-between py-4 mb-4${
        expanded || hover
          ? 'py-2 pl-4'
          : 'justify-center py-2 sm:px-2 md:p-2 md:px-4'
      }`}
    >
      {(expanded || hover) && (
        <h2 className="rounded-full text-2xl text-white">
          <Link to="/">
            <img src={logo} alt="logo" className="h-[35px] w-[35px]"></img>
          </Link>
        </h2>
      )}
      <Button
        icon={`pi ${expanded ? 'pi-chevron-left' : 'pi-chevron-right'}`}
        className="p-button-text p-button-plain text-white"
        onClick={toggleSidebar}
      />
    </div>
  );
};

export default SidebarHeader;
