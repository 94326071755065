import React from 'react';
import MainLayout from '../components/MainLayout';
import SettingsComponent from '../components/settings/SettingsComponent';
import DeleteAccount from '../components/settings/DeleteAccount';
import { useDeleteAccount } from '../hooks/DeleteAccountContext';
import ProtectedPage from './ProtectedPage';
const Settings: React.FC = () => {
  const { deleteAccount } = useDeleteAccount();
  return (
    <ProtectedPage>
      <div>
        <MainLayout>
          {!deleteAccount && <SettingsComponent />}
          {deleteAccount && <DeleteAccount />}
        </MainLayout>
      </div>
    </ProtectedPage>
  );
};

export default Settings;
