import MainLayout from '../components/MainLayout';
import { useEffect, useState } from 'react';
import { ProfileHeader } from '../types';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import Header from '../components/yourwork/Header';
import { Outlet } from 'react-router-dom';
import ProtectedPage from './ProtectedPage';

const YourWork = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const [profile, setProfile] = useState<ProfileHeader>();
  const fetchProfile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${user.id}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setProfile(data);
      } else {
        console.log('Error fetching replies');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);
  return (
    <ProtectedPage>
      <MainLayout>
        <div className="my-24 lg:my-10">
          <header className="mb-4 flex flex-col justify-between gap-3 lg:gap-4">
            <h1 className="text-left text-4xl font-bold">Your Work</h1>
            {profile && <Header {...profile} id={user.id} />}
          </header>
          <Outlet />
        </div>
      </MainLayout>
    </ProtectedPage>
  );
};

export default YourWork;
