import React from 'react';
import ButtonIcon from '../../atoms/common/ButtonIcon';
import { Link } from 'react-router-dom';

interface ActiveCompetitionItemProps {
  id: number;
  title: string;
  timeLeft: string;
  coverImage: string;
}

const ActiveCompetitionItem: React.FC<ActiveCompetitionItemProps> = ({
  id,
  title,
  timeLeft,
  coverImage,
}) => {
  return (
    <div
      className={`mb-1 flex items-center justify-between rounded-lg border bg-white py-2 shadow-sm transition-shadow duration-300 hover:shadow-lg lg:px-4`}
    >
      <Link to={`/competitions/${id}`}>
        <div className="flex items-center gap-2 md:space-x-4">
          <img
            src={coverImage}
            alt={title}
            className="h-10 max-w-10 rounded-full object-cover"
          />
          <div className="text-start">
            <h3 className="text-left text-lg font-semibold">{title}</h3>
            <p className="text-xs text-secondary-text lg:text-sm">
              {timeLeft} to ago
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ActiveCompetitionItem;
