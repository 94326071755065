import { useEffect, useState } from 'react';
import Navbar from '../components/landing/Navbar';

import { Outlet } from 'react-router-dom';
import Footer from '../components/landing/Footer';

const Landing = () => {
  const [onTop, setOnTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setOnTop(false);
      } else {
        setOnTop(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="relative">
      <Navbar onTop={onTop} />
      <Outlet />
      <div className="h-[30vh] w-full"></div>
      <Footer />
    </div>
  );
};

export default Landing;
