import React, { useEffect, useState } from 'react';
import SubmissionItem from '../../atoms/competition/SubmissionItem';
import SearchBar from '../header/SearchBar';
import { Submission } from '../../types';
import { useParams } from 'react-router-dom';

const Submissions: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [submissions, setSubmissions] = useState<Submission[]>([]);

  const fetchSubmissions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/${id}/submissions`,
        {
          method: 'GET',
          credentials: 'include',

          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        console.error('Failed to fetch submissions:', response.statusText);
        return;
      }
      const data = await response.json();
      console.log('Submissions:', data);
      setSubmissions(data);
    } catch (error) {
      console.error('Error fetching submissions:', error);
    }
  };

  useEffect(() => {
    fetchSubmissions();
  }, []);

  return (
    <div className="flex flex-col justify-start gap-10 text-start">
      <h2 className="text-2xl font-bold">Submissions</h2>
      <div className="mx-auto w-full">
        <SearchBar placeholder="Search Submissions" />

        <div className="mt-2 flex flex-col gap-2">
          {submissions.map((submission) => (
            <SubmissionItem
              key={submission.id}
              id={submission.id}
              upvotedByMe={submission.upvotedByMe}
              userPicture={submission.user.full_name}
              title={submission.title}
              updated_at={submission.updated_at || submission.created_at}
              upvotes={submission._count.submissionVotes}
              commentsCount={submission._count.submissionVotes}
              competitionName={'Competition Name'}
              medalType={submission?.medal_type}
              onClick={() =>
                (window.location.href = `/competitions/${id}/submissions/${submission.id}`)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Submissions;
