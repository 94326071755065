import { useState, useEffect } from 'react';
import { Follower } from '../../types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import FollowButton from '../general/FollowButton';

const Followers = () => {
  const [type, setType] = useState<'followers' | 'followings'>('followers');
  const [followers, setFollowers] = useState<Follower[]>();
  const { id } = useParams();
  const user = useSelector((state: RootState) => state.user.user);

  const fetchFollowers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${window.location.pathname.split('/')[1] !== 'your-work' ? id : user.id}/${type}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setFollowers(data);
      } else {
        console.log('Error fetching followers');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFollowers();
  }, [type]);
  return (
    <div className="overflow-hidden rounded-xl text-start lg:border">
      <h2 className="bg-gray-200 px-4 py-2">
        {type[0].toUpperCase() + type.slice(1)}
      </h2>
      <ul className="flex items-center gap-4 border-b px-4 py-2">
        <li
          onClick={() => setType('followers')}
          className={`cursor-pointer ${type === 'followers' ? 'text-black' : 'text-gray-500'}`}
        >
          Followers
        </li>
        <li
          onClick={() => setType('followings')}
          className={`cursor-pointer ${type === 'followings' ? 'text-black' : 'text-gray-500'}`}
        >
          Following
        </li>
      </ul>
      <div>
        {followers?.map((follower) => (
          <a
            href={`/profile/${follower.id}`}
            key={follower.id}
            className="flex items-center gap-3 border-b p-4"
          >
            <img
              src={follower.profile_image}
              alt={follower.full_name}
              className="aspect-square w-[40px] rounded-full"
            />
            <div className="flex-grow">
              <h3>{follower.full_name}</h3>
            </div>
            <FollowButton is_followed={follower.is_followed} id={follower.id} />
          </a>
        ))}
        {followers?.length === 0 && (
          <div className="p-4 text-center text-primary-color">No {type}</div>
        )}
      </div>
    </div>
  );
};

export default Followers;
