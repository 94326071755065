import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { useDispatch } from 'react-redux';
import { signOut } from '../../store/UserSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Link } from 'react-router-dom';

const IMG_URL =
  // user.avatar ||
  'https://primefaces.org/cdn/primereact/images/organization/walter.jpg';
const AvatarComponent: React.FC = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user.user);

  const name = user.full_name || 'User Name';
  const title = user.job_title;

  const handleSignout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/signout`,
        {
          method: 'POST',
          credentials: 'include',

          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        dispatch(signOut());
        navigate('/landing');
        setVisible(false);
      } else {
        console.log('Error while signing out' + response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const menuItems =
    user?.role === 'ADMIN'
      ? [
          {
            label: 'Your Profile',
            icon: 'pi pi-user',
            href: '/your-work',
          },
          {
            label: 'Admin Dashboard',
            icon: 'pi pi-th-large',
            href: '/dashboard',
          },
          {
            label: 'Host Dashboard',
            icon: 'pi pi-th-large',
            href: '/host-dashboard',
          },
          {
            label: 'Settings',
            icon: 'pi pi-cog',
            href: '/settings',
          },
          {
            label: 'Sign out',
            icon: 'pi pi-sign-out',
            onClick: () => {
              handleSignout();
            },
          },
          { separator: true },
        ]
      : [
          {
            label: 'Your Profile',
            icon: 'pi pi-user',
            href: '/your-work',
          },

          {
            label: 'Host Dashboard',
            icon: 'pi pi-th-large',
            href: '/host-dashboard',
          },
          {
            label: 'Settings',
            icon: 'pi pi-cog',
            href: '/settings',
          },
          {
            label: 'Sign out',
            icon: 'pi pi-sign-out',
            onClick: () => {
              handleSignout();
            },
          },
          { separator: true },
        ];

  interface MenuItemProps {
    label: string;
    icon: string;
    href?: string;
    onClick?: () => void;
    separator?: boolean;
  }

  const MenuItem: React.FC<MenuItemProps> = ({
    label,
    icon,
    href,
    onClick,
  }) => (
    <Link
      to={href || '#'}
      className="flex cursor-pointer items-center space-x-7 px-4 py-2 hover:bg-gray-200"
      onClick={onClick}
    >
      <i className={`${icon} text-primary-text`} />
      <span className="text-primary-text">{label}</span>
    </Link>
  );

  return (
    <div className="flex items-center justify-center">
      <Sidebar
        visible={visible}
        onHide={() => setVisible(false)}
        className="p-sidebar-sm"
        position="right"
      >
        <div className="flex flex-col items-center gap-1">
          <Avatar image={user.profile_image} shape="circle" size="xlarge" />
          <div className="mt-2 text-xl font-bold text-primary-text">{name}</div>
          <div className="text-lg text-primary-text">{title}</div>
        </div>
        <hr className="my-5" />
        {menuItems.map((item, index) =>
          item.separator ? (
            <hr className="mb-3 mt-6" key={index} />
          ) : (
            <MenuItem
              key={index}
              label={item.label || ''}
              href={item.href}
              icon={item.icon || ''}
              onClick={item.onClick}
            />
          )
        )}
      </Sidebar>

      <Button id="avatarButton" onClick={() => setVisible(true)}>
        <Avatar
          image={user.profile_image}
          className="p-overlay-badge"
          size="large"
          shape="circle"
        />
      </Button>
    </div>
  );
};

export default AvatarComponent;
