import competitionsScreen from '../../assets/competitionsScreen.svg';
import tackleProject from '../../assets/tackleProject.svg';
import tackleProject2 from '../../assets/tackleProject2.svg';

const BidayyaUsefulness = () => {
  return (
    <div className="relative">
      <div className="mx-auto flex max-w-[1480px] flex-wrap gap-8 rounded-xl border bg-primary-color p-5 shadow-sm">
        <div className="flex max-w-[513px] flex-col gap-8 text-left">
          <h2 className="text-7xl font-extrabold">Tackle your first project</h2>
          <div className="flex text-2xl font-semibold">
            <div className="border-r border-r-black pr-16">
              <p>50+</p>
              <p>Projects</p>
            </div>
            <div className="pl-16">
              <p>100+</p>
              <p>Customers</p>
            </div>
          </div>
        </div>
        <img src={tackleProject} alt="" className="w-full max-w-[760px]" />
      </div>
      <img
        src={tackleProject2}
        className="absolute top-1/2 -z-50 -translate-y-1/2"
        alt=""
      />
      <div className="mt-[30vh] flex flex-wrap justify-center gap-20 px-3">
        <img src={competitionsScreen} alt="" className="w-full max-w-[760px]" />
        <div className="flex max-w-[600px] flex-col gap-3 text-left">
          <h2 className="text-xl font-bold">
            Empower Your Design Career with Bidayya
          </h2>
          <p className="text-lg">
            Dive into competitions that challenge and showcase your talent,
            offering real impact and recognition in the design world.
          </p>
          <h2 className="text-xl font-bold">Diverse Design Challenges</h2>
          <p className="text-lg">
            Explore a wide array of real-world design challenges across various
            industries. Each competition on Bidayya is a chance to test and
            showcase your skills, work on engaging projects, and get recognized
            by potential clients and employers.
          </p>
          <h2 className="text-xl font-bold">Real Impact, Real Recognition</h2>
          <p className="text-lg">
            Participating in Bidayya competitions means more than just
            submitting designs. It’s about making a tangible impact with your
            creativity and gaining recognition from industry leaders. Winners
            not only earn accolades but also have their work showcased to a
            global audience, opening doors to exciting career opportunities.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BidayyaUsefulness;
