import { useDispatch, useSelector } from 'react-redux';
import MainLayout from '../components/MainLayout';

import { useEffect, useRef, useState } from 'react';
import { RootState } from '../store';
import { ProfileHeader } from '../types';

import {
  CheckboxWithLabel,
  CompetitionFormField,
} from '../components/createCompetition/CompetitionForm';
import Crop from '../components/general/Crop/Crop';
import { Dialog } from 'primereact/dialog';
import StyledButton from '../atoms/common/StyledButton';
import ProtectedPage from './ProtectedPage';
import { useNavigate } from 'react-router-dom';

const EditProfile = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const [profile, setProfile] = useState<ProfileHeader>();
  const [name, setName] = useState<string>('');
  const [job, setJob] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [link, setLink] = useState<string>('');
  const [joinDatePrivacy, setJoinDatePrivacy] = useState<
    'ANYONE' | 'FOLLOWERS' | 'NOONE'
  >(profile?.seeing_join_date_privacy || 'ANYONE');
  const [lastSeenPrivacy, setLastSeenPrivacy] = useState<
    'ANYONE' | 'FOLLOWERS' | 'NOONE'
  >(profile?.last_seen_and_online_privacy || 'ANYONE');

  const dispatch = useDispatch();

  const fetchProfile = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profile/${user.id}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setProfile(data);
        setName(data.full_name);
        setJob(data.job_title);
        setCompany(data.company);
        setLocation(data.location);
        setLink(data.link);
        setJoinDatePrivacy(data.seeing_join_date_privacy);
        setLastSeenPrivacy(data.last_seen_and_online_privacy);
        return data;
      } else {
        console.log('Error fetching replies');
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const updateProfile = () => {
    fetchProfile().then((res) => {
      console.log(res);
      // dispatch(updateProfile({ user: res }));
    });
  };

  const [openCrop, setOpenCrop] = useState(false);

  const [profilePic, setProfilePic] = useState<File | null>();
  const [profilePicURL, setProfilePicURL] = useState(
    profile?.profile_image || ''
  );

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handlePictureClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };

  const handlePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];

    setProfilePic(fileUploaded);
    setProfilePicURL(URL.createObjectURL(fileUploaded || new Blob()));

    setOpenCrop(true);
  };

  const navigate = useNavigate();
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleSave = async () => {
    setButtonClicked(true);
    const formData = new FormData();
    if (name) formData.append('full_name', name);
    if (job) formData.append('job_title', job);
    if (company) formData.append('company', company);
    if (location) formData.append('location', location);
    if (link) formData.append('link', link);
    if (profilePic) formData.append('profile_image', profilePic);
    formData.append('seeing_join_date_privacy', joinDatePrivacy);
    formData.append('last_seen_and_online_privacy', lastSeenPrivacy);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/profile`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          contentType: 'multipart/form-data',
        },
        body: formData,
      });

      if (response.ok) {
        //navigate('/your-work');
        updateProfile();
      } else {
        console.log('Error updating profile');
        setError(true);
        setButtonClicked(false);
      }
      setButtonClicked(false);
    } catch (error) {
      console.error(error);
      setError(true);
      setButtonClicked(false);
    }
  };

  return (
    <ProtectedPage>
      <MainLayout>
        <div className="my-24 flex flex-col gap-3 text-start transition-all duration-300 lg:w-1/2 lg:gap-4 xl:mx-8">
          <h1 className="text-start text-2xl font-bold sm:text-4xl">
            Edit Public Profile
          </h1>
          <h2 className="text-2xl font-semibold">Basic Info</h2>

          <CompetitionFormField
            id="name"
            label="Name"
            value={name}
            onChange={(value) => setName(value)}
            placeholder={profile?.full_name}
            maxLength={50}
          />
          <CompetitionFormField
            id="job"
            label="Job"
            value={job}
            onChange={(value) => setJob(value)}
            placeholder={profile?.job_title}
            maxLength={50}
          />
          <CompetitionFormField
            id="company"
            label="Company Name"
            value={company}
            onChange={(value) => setCompany(value)}
            placeholder={profile?.company}
            maxLength={50}
          />
          <CompetitionFormField
            id="link"
            label="LinkedIn"
            value={link}
            onChange={(value) => setLink(value)}
            placeholder={profile?.link}
            maxLength={80}
          />
          <CompetitionFormField
            id="location"
            label="City"
            value={location}
            onChange={(value) => setLocation(value)}
            placeholder={profile?.location}
            maxLength={50}
          />
          <h1 className="my-2 text-start text-lg font-bold sm:text-xl">
            Profile Image
          </h1>
          <div className="relative m-auto w-fit rounded-full border-2 border-black">
            <div className="w-fit rounded-full border border-white">
              <img
                src={profilePicURL ? profilePicURL : profile?.profile_image}
                alt="profile"
                className="h-[200px] w-[200px] rounded-full"
              />
            </div>
            <button
              className="bg-opacity-200 absolute left-[50%] top-[50%] m-auto h-[47px] w-[47px] -translate-x-[50%] -translate-y-[50%] rounded-full bg-gray-500 hover:bg-gray-600 hover:bg-opacity-50"
              onClick={handlePictureClick}
            >
              <i className="pi-camera pi text-2xl"></i>
              <input
                type="file"
                accept="image/*"
                onChange={handlePictureChange}
                ref={hiddenFileInput}
                style={{ display: 'none' }}
              />
            </button>
          </div>
          <Dialog
            header="Crop Image"
            visible={openCrop}
            style={{
              width: '90vw',
              maxWidth: '800px',
              padding: '.2rem',
            }}
            onHide={() => {
              setProfilePicURL(profile?.profile_image || '');
              setOpenCrop(false);
            }}
            draggable={false}
          >
            <Crop
              photoURL={profilePicURL}
              setOpenCrop={setOpenCrop}
              setPhotoURL={setProfilePicURL}
              setFile={setProfilePic}
              originalPhoto={profile?.profile_image || ''}
            />
          </Dialog>
          <h2 className="text-2xl font-semibold">Privacy</h2>
          <div>
            <h1 className="my-2 block text-start text-sm font-medium text-black">
              Who can see your last seen & online status?{' '}
              <span className="text-red-600">*</span>
            </h1>
            <div className="flex flex-row justify-between">
              <CheckboxWithLabel
                checked={joinDatePrivacy === 'ANYONE'}
                onChange={() => {
                  setJoinDatePrivacy('ANYONE');
                }}
                label="Everyone"
                description=""
              />
              <CheckboxWithLabel
                checked={joinDatePrivacy === 'FOLLOWERS'}
                onChange={() => setJoinDatePrivacy('FOLLOWERS')}
                label="Followers only"
                description=""
              />
              <CheckboxWithLabel
                checked={joinDatePrivacy === 'NOONE'}
                onChange={() => setJoinDatePrivacy('NOONE')}
                label="No one"
                description=""
              />
            </div>
          </div>
          <div>
            <h1 className="my-2 block text-start text-sm font-medium text-black">
              Who can see your join date?{' '}
              <span className="text-red-600">*</span>
            </h1>
            <div className="flex flex-row justify-between">
              <CheckboxWithLabel
                checked={lastSeenPrivacy === 'ANYONE'}
                onChange={() => {
                  setLastSeenPrivacy('ANYONE');
                }}
                label="Everyone"
                description=""
              />
              <CheckboxWithLabel
                checked={lastSeenPrivacy === 'FOLLOWERS'}
                onChange={() => setLastSeenPrivacy('FOLLOWERS')}
                label="Followers only"
                description=""
              />
              <CheckboxWithLabel
                checked={lastSeenPrivacy === 'NOONE'}
                onChange={() => setLastSeenPrivacy('NOONE')}
                label="No one"
                description=""
              />
            </div>
          </div>

          <div className="mt-5 flex items-center justify-start gap-3">
            <StyledButton
              label="Save"
              variant="primary"
              onClick={handleSave}
              disabled={buttonClicked}
            />
            <StyledButton
              label="Discard"
              variant="secondary"
              onClick={() => fetchProfile()}
            />
          </div>
          {error && (
            <p className="text-red-600">
              There is something wrong, please try again
            </p>
          )}
        </div>
      </MainLayout>
    </ProtectedPage>
  );
};

export default EditProfile;
