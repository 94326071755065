import { AdminCompetition } from '../types/AdminCompetition';
import { CompetitionDetails } from '../types/CompetitionDetails';
import { FeedbackMessage } from '../types/FeedbackMessage';
import { RunningCompetition } from '../types/RunningCompetition';
import { Submission } from '../types/Submission';
import { fetchApi } from './utils/fetchApi';

export const getCompetitions = ({
  tags,
  rated,
  ongoing,
  joined,
}: {
  tags?: Array<number>;
  rated?: boolean;
  ongoing?: boolean;
  joined?: boolean;
}): Promise<CompetitionDetails[]> => {
  return fetchApi<CompetitionDetails[]>('competitions/home', {
    method: 'GET',
    queryParams: {
      ...(tags?.length && { tags: tags.join(',') }),
      ...(rated !== undefined && { rated }),
      ...(ongoing !== undefined && { ongoing }),
      ...(joined !== undefined && { joined }),
    },
  });
};

export const getCompetitionById = ({
  id,
}: {
  id: string;
}): Promise<CompetitionDetails> => {
  return fetchApi<CompetitionDetails>(`competitions/${id}`, {
    method: 'GET',
  });
};

export const checkUrlExists = ({ url }: { url: string }): Promise<any> => {
  return fetchApi<any>(`competitions/${url}/check-if-url-exists`, {
    method: 'GET',
  });
};

export const getCompetitionByURL = ({
  url,
}: {
  url: string;
}): Promise<CompetitionDetails> => {
  return fetchApi<CompetitionDetails>(`competitions/${url}`, {
    method: 'GET',
  });
};

export const joinCompetitionById = ({ id }: { id: string }): Promise<void> => {
  return fetchApi<void>(`competitions/${id}/join`, {
    method: 'POST',
  });
};

export const rejectCompetitionById = ({
  id,
}: {
  id: string;
}): Promise<void> => {
  return fetchApi<void>(`competitions/${id}/reject`, {
    method: 'PATCH',
  });
};

export const acceptCompetitionById = ({
  id,
}: {
  id: string;
}): Promise<void> => {
  return fetchApi<void>(`competitions/${id}/accept`, {
    method: 'PATCH',
  });
};

export const submitCompetitionFeedback = ({
  id,
  content,
  files,
  isFromAdmin,
}: {
  id: string;
  content: string;
  files: FileList | null;
  isFromAdmin: boolean;
}): Promise<FeedbackMessage> => {
  const formData = new FormData();
  formData.append('content', content);
  formData.append('isFromAdmin', isFromAdmin ? 'true' : 'false');
  files &&
    Array.from(files).forEach((file) => {
      formData.append('file', file);
    });
  return fetchApi<FeedbackMessage>(`competitions/${id}/send-feedback`, {
    method: 'POST',
    formData: formData,
  });
};

export const getFeedbackMessagesByCompetitionId = ({
  id,
}: {
  id: string;
}): Promise<FeedbackMessage[]> => {
  return fetchApi<FeedbackMessage[]>(`competitions/${id}/feedbacks`, {
    method: 'GET',
  });
};

export const getLeaderboardByCompetitionId = ({
  id,
}: {
  id: string;
}): Promise<any> => {
  return fetchApi<any>(`competitions/${id}/leaderboard`, {
    method: 'GET',
  });
};

export const getSubmissionsByCompetitionId = ({
  id,
}: {
  id: string;
}): Promise<Submission[]> => {
  return fetchApi<Submission[]>(`competitions/${id}/submissions`, {
    method: 'GET',
  });
};

export const getApprovedCompetitions = (): Promise<AdminCompetition[]> => {
  return fetchApi<AdminCompetition[]>(`competitions/approved`, {
    method: 'GET',
  });
};

export const getDeclinedCompetitions = (): Promise<AdminCompetition[]> => {
  return fetchApi<AdminCompetition[]>(`competitions/declined`, {
    method: 'GET',
  });
};

export const getNewCompetitions = (): Promise<AdminCompetition[]> => {
  return fetchApi<AdminCompetition[]>(`competitions/new`, {
    method: 'GET',
  });
};

export const getUnderReviewCompetitions = (): Promise<AdminCompetition[]> => {
  return fetchApi<AdminCompetition[]>(`competitions/under-review`, {
    method: 'GET',
  });
};

export const getAllSubmissionsByCompetitionId = ({
  id,
}: {
  id: string;
}): Promise<RunningCompetition> => {
  return fetchApi<RunningCompetition>(`competitions/${id}/all-submissions`, {
    method: 'GET',
  });
};

export const awardGoldRanking = ({
  competitionId,
  submissionIds,
}: {
  competitionId: string;
  submissionIds: number[];
}): Promise<void> => {
  return fetchApi<void>(
    `competitions/${competitionId}/submissions/award-gold-medal`,
    {
      method: 'POST',
      body: {
        submissionIds,
      },
    }
  );
};

export const awardSilverRanking = ({
  competitionId,
  submissionIds,
}: {
  competitionId: string;
  submissionIds: number[];
}): Promise<void> => {
  return fetchApi<void>(
    `competitions/${competitionId}/submissions/award-silver-medal`,
    {
      method: 'POST',
      body: {
        submissionIds,
      },
    }
  );
};

export const awardBronzeRanking = ({
  competitionId,
  submissionIds,
}: {
  competitionId: string;
  submissionIds: number[];
}): Promise<void> => {
  return fetchApi<void>(
    `competitions/${competitionId}/submissions/award-bronze-medal`,
    {
      method: 'POST',
      body: {
        submissionIds,
      },
    }
  );
};

export const awardWinner = ({
  competitionId,
  selectedWinner,
}: {
  competitionId: string;
  selectedWinner: number;
}): Promise<void> => {
  return fetchApi<void>(
    `competitions/${competitionId}/submissions/${selectedWinner}/mark-as-winner`,
    {
      method: 'PATCH',
    }
  );
};

export const getMyDeclinedCompetitions = (): Promise<AdminCompetition[]> => {
  return fetchApi<AdminCompetition[]>(`competitions/my-declined`, {
    method: 'GET',
  });
};

export const getMyEndedCompetitions = (): Promise<AdminCompetition[]> => {
  return fetchApi<AdminCompetition[]>(`competitions/my-ended`, {
    method: 'GET',
  });
};

export const getMyNewCompetitions = (): Promise<AdminCompetition[]> => {
  return fetchApi<AdminCompetition[]>(`competitions/my-new`, {
    method: 'GET',
  });
};

export const getMyRatedCompetitions = (): Promise<AdminCompetition[]> => {
  return fetchApi<AdminCompetition[]>(`competitions/my-rated`, {
    method: 'GET',
  });
};

export const getMYOngoingCompetitions = (): Promise<AdminCompetition[]> => {
  return fetchApi<AdminCompetition[]>(`competitions/my-ongoing`, {
    method: 'GET',
  });
};

export const getMyUnderReviewCompetitions = (): Promise<AdminCompetition[]> => {
  return fetchApi<AdminCompetition[]>(`competitions/my-under-review`, {
    method: 'GET',
  });
};

export const addCompetition = ({
  files,
  members_file,
  cover_image,
  competition_name,
  description,
  company_name,
  price,
  rules,
  overview,
  competition_url,
  tags,
  submission_deadline,
  is_private,
  access_type,
}: {
  files: Array<File>;
  members_file: Array<File>;
  cover_image: File;
  competition_name: string;
  description: string;
  company_name: string;
  price: number;
  rules: string;
  overview: string;
  competition_url: string;
  tags: Array<number>;
  submission_deadline: string;
  is_private: boolean;
  access_type: string;
}): Promise<CompetitionDetails> => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('files', file);
  });
  console.log('members_file', members_file);
  console.log(members_file.length > 0);
  if (members_file.length > 0) {
    formData.append('user_emails', members_file[0]);
  }
  formData.append('cover_image', cover_image);
  formData.append('competition_name', competition_name);
  formData.append('description', description);
  formData.append('company_name', company_name);
  formData.append('price', price.toString());
  formData.append('rules', rules);
  formData.append('overview', overview);
  formData.append('competition_url', competition_url);
  tags.forEach((tag) => {
    formData.append('tags', tag.toString());
  });
  formData.append('submission_deadline', submission_deadline);
  formData.append('is_private', is_private.toString());
  formData.append('access_type', access_type);

  return fetchApi<CompetitionDetails>(`competitions`, {
    method: 'POST',
    formData: formData,
  });
};

export const editCompetition = ({
  id,
  files,
  cover_image,
  members_file,
  competition_name,
  description,
  company_name,
  price,
  rules,
  overview,
  competition_url,
  tags,
  submission_deadline,
  is_private,
  access_type,
}: {
  id: string;
  files: Array<File>;
  cover_image: File;
  members_file: Array<File>;
  competition_name: string;
  description: string;
  company_name: string;
  price: number;
  rules: string;
  overview: string;
  competition_url: string;
  tags: Array<number>;
  submission_deadline: string;
  is_private: boolean;
  access_type: string;
}): Promise<CompetitionDetails> => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('files', file);
  });
  if (members_file.length > 0) {
    formData.append('user_emails', members_file[0]);
  }
  formData.append('cover_image', cover_image);
  formData.append('competition_name', competition_name);
  formData.append('description', description);
  formData.append('company_name', company_name);
  formData.append('price', price.toString());
  formData.append('rules', rules);
  formData.append('overview', overview);
  formData.append('competition_url', competition_url);
  tags.forEach((tag) => {
    formData.append('tags', tag.toString());
  });
  formData.append('submission_deadline', submission_deadline);
  formData.append('is_private', is_private.toString());
  formData.append('access_type', access_type);

  return fetchApi<CompetitionDetails>(`competitions/${id}`, {
    method: 'PATCH',
    formData: formData,
  });
};

export const toggleCompetitionBookmark = ({
  id,
}: {
  id: string;
}): Promise<any> => {
  return fetchApi<any>(`competitions/${id}/toggle-bookmark`, {
    method: 'POST',
  });
};
