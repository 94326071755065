import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Submission } from '../types';
import SubmissionForm from '../components/submissions/SubmissionForm';

const EditSubmission = () => {
  const { id, submissionId } = useParams();
  const [submission, setSubmission] = useState<Submission>();
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [is_private, setIsPrivate] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSubmission = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/submissions/${submissionId}/get-my-submission`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setError('');
        setSubmission(data);
      } else {
        console.error('Error fetching submission:', response.statusText);
        setError('Not Found');
      }
    } catch (error) {
      setError('Not Found');

      console.error('Error fetching submission:', error);
    }
  };
  useEffect(() => {
    fetchSubmission();
  }, []);
  const urlToFile = async (url: string, fileName: string): Promise<File> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], fileName, { type: blob.type });
  };

  const convertCoverAndFiles = async (
    coverUrl: string,
    filesUrls: string[]
  ): Promise<{ coverFile: File; files: File[] }> => {
    const coverFile = await urlToFile(coverUrl, coverUrl.split('/').pop()!);

    const files = await Promise.all(
      filesUrls.map(async (fileUrl, index) => {
        return await urlToFile(fileUrl, fileUrl.split('/').pop()!);
      })
    );

    return { coverFile, files };
  };

  useEffect(() => {
    if (submission) {
      setTitle(submission.title);
      setDescription(submission.description);
      setIsPrivate(submission.is_private);
      convertCoverAndFiles(submission.cover_image, submission.files).then(
        ({ coverFile, files }) => {
          setCoverImage(coverFile);
          setFiles(files);
        }
      );
    }
  }, [submission]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('cover_image', coverImage as File);
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('is_private', is_private.toString());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/${id}/submissions/${submissionId}`,
        {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            contentType: 'multipart/form-data',
          },
          body: formData,
        }
      );
      if (response.ok) {
        window.location.href = `/competitions/${id}`;
      } else {
        console.error('Error submitting submission:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting submission:', error);
    }
  };

  return (
    <>
      {error === '' && (
        <div className="mt-24 flex flex-col gap-3 transition-all duration-300 lg:w-1/2 lg:gap-4 xl:mx-8">
          <SubmissionForm
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            coverImage={coverImage}
            setCoverImage={setCoverImage}
            files={files}
            setFiles={setFiles}
            is_private={is_private}
            setIsPrivate={setIsPrivate}
            handleSubmit={handleSubmit}
          />
        </div>
      )}
      {error && <div className="mx-auto mt-24 text-2xl md:my-10">{error}</div>}
    </>
  );
};

export default EditSubmission;
