import landingInfo1 from '../../assets/landingInfo1.svg';
import landingInfo2 from '../../assets/landingInfo2.svg';

const Info = () => {
  return (
    <div className="mx-auto max-w-[1540px] -translate-y-10 border bg-white p-5 shadow-2xl">
      <div className="flex w-full flex-col items-center gap-1">
        <h2 className="text-3xl font-bold">What is Bidayya?</h2>
        <p className="text-xl">A place to participate in design challenges</p>
        <p className="text-xl">Get prices</p>
        <p className="text-xl">Get recognition</p>
        <p className="text-xl">Recruiters will run after You</p>
      </div>
      <div className="flex w-full flex-wrap items-center justify-around">
        <InfoCard
          title="Talent designer"
          image={landingInfo1}
          description="90% of recent design graduates feel underprepared for professional design career"
        />
        <InfoCard
          title="SME Challenges"
          image={landingInfo2}
          description="70% of small and medium-sized enterprises cite budget constraints as a major barrier to hiring professional design services"
        />
      </div>
    </div>
  );
};

const InfoCard = ({
  title,
  image,
  description,
}: {
  title: string;
  image: string;
  description: string;
}) => {
  return (
    <div className="flex max-w-[348px] flex-col items-center gap-3 p-4">
      <img src={image} alt="" className="w-[242px]" />
      <h3 className="text-2xl font-bold">{title}</h3>
      <p className="text-center">{description}</p>
    </div>
  );
};

export default Info;
