import BidayyaComing from './BidayyaComing';
import BidayyaUsefulness from './BidayyaUsefulness';
import CompsAndSubms from './CompsAndSubms';
import Hero from './Hero';
import Info from './Info';

const LandingHome = () => {
  return (
    <div>
      <Hero />
      <Info />
      <BidayyaComing />
      <BidayyaUsefulness />
      <CompsAndSubms />
    </div>
  );
};

export default LandingHome;
