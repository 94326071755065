import { PiSpinnerLight } from 'react-icons/pi';

import landingHero from '../../assets/landingHero.svg';
import landingHero2 from '../../assets/landingHero2.svg';
import landingHeroPrush from '../../assets/landingHeroPrush.svg';
import landingHeroImage from '../../assets/landingHeroImage.svg';

const Hero = () => {
  return (
    <div className="relative flex h-screen items-center overflow-hidden bg-primary-color pt-[15vh] max-2xl:flex-col max-2xl:gap-8 max-xl:px-5 xl:px-[200px] 2xl:justify-between 2xl:pt-[20vh]">
      <div className="flex flex-col items-start gap-3 text-start text-white md:w-[650px]">
        <p className="text-xl font-semibold">Enjoy the real world of design</p>
        <h2 className="text-5xl font-bold">Real Challenges,</h2>
        <h2 className="text-5xl font-bold">Real Prices, Real Recognition</h2>
        <p className="text-xl font-semibold">
          Dive into authentic design challenges from real businesses across the
          globe. Showcase your creativity, and earn recognition that propels
          your career forward.
        </p>
        <button className="flex items-center gap-3 rounded-lg bg-white px-4 py-2 text-xl font-semibold text-black">
          Register
          <PiSpinnerLight className="text-2xl" />
        </button>
      </div>
      <div className="relative h-[450px] w-[400px] shrink-0 rounded-[50%] border-2 border-dashed max-w500:h-[290px] max-w500:w-[280px] 2xl:h-[513px] 2xl:w-[480px]">
        <img
          src={landingHeroImage}
          alt=""
          className="absolute left-[50%] top-[47%] aspect-square max-w-[380px] -translate-x-1/2 -translate-y-1/2 max-w500:max-w-[260px] 2xl:left-[45%] 2xl:max-w-[413px]"
        />
        <img
          src={landingHeroPrush}
          alt=""
          className="absolute -left-[5%] top-[12%]"
        />
        <img
          src={landingHero2}
          alt=""
          className="absolute -left-[15%] top-[42%]"
        />
        <img
          src={landingHero}
          alt=""
          className="absolute -right-[20%] -top-[10%]"
        />
      </div>

      <div className="absolute -left-[132px] top-[23vh] h-[307px] w-[264px] rounded-[50%] border-2 border-dashed border-white"></div>
      <div className="absolute -bottom-20 -left-16 h-28 w-36 rotate-[25deg] bg-white"></div>
    </div>
  );
};

export default Hero;
