import React, { useEffect, useState } from 'react';
import FormField from '../../atoms/auth/FormField';
import StyledButton from '../../atoms/common/StyledButton';
import ImageUploadField from '../ImageUploadField';
import FileUploadField from '../FileUploadField';
import ButtonGroup from '../../atoms/common/ButtonGroup';
import Checkbox from './Checkbox';
import uploadIcon from '../../assets/uploadIcon.png';
import { Tag } from '../../types/Tag';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CompetitionCreate } from '../../types/CompetitionCreate';
import { Dialog } from 'primereact/dialog';
import { checkUrlExists } from '../../api/competionApi';
import { CompetitionAccessType } from '../../types/CompetitionDetails';

interface CompetitionFormProps {
  buttonClicked: boolean;
  edit: boolean;
  onSubmit: () => void;
  setUrlExists: (exists: boolean) => void;
  urlExists: boolean;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  coverImage: File | null;
  setCoverImage: React.Dispatch<React.SetStateAction<File | null>>;
  membersFile: File[];
  setMembersFile: React.Dispatch<React.SetStateAction<File[]>>;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  price: number;
  setPrice: React.Dispatch<React.SetStateAction<number>>;
  rules: string;
  setRules: React.Dispatch<React.SetStateAction<string>>;
  overview: string;
  setOverview: React.Dispatch<React.SetStateAction<string>>;
  competitionUrlS: string;
  setCompetitionUrlS: React.Dispatch<React.SetStateAction<string>>;
  tags: Tag[];
  setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
  submissionDeadline: string;
  setSubmissionDeadline: React.Dispatch<React.SetStateAction<string>>;
  isPrivate: boolean;
  setIsPrivate: React.Dispatch<React.SetStateAction<boolean>>;
  accessType: CompetitionAccessType;
  setAccessType: React.Dispatch<React.SetStateAction<CompetitionAccessType>>;
}

const CompetitionForm: React.FC<CompetitionFormProps> = ({
  buttonClicked,
  edit,
  onSubmit,
  setUrlExists,
  urlExists,
  title,
  setTitle,
  description,
  setDescription,
  coverImage,
  setCoverImage,
  files,
  setFiles,
  membersFile,
  setMembersFile,
  companyName,
  setCompanyName,
  price,
  setPrice,
  rules,
  setRules,
  overview,
  setOverview,
  competitionUrlS,
  setCompetitionUrlS,
  tags,
  setTags,
  submissionDeadline,
  setSubmissionDeadline,
  isPrivate,
  setIsPrivate,
  accessType,
  setAccessType,
}) => {
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const handleCompetitionUrlChange = (value: string) => {
    if (value.includes('bidayya.com/competitions') || competitionUrlS == '') {
      let v = value.replace('bidayya.com/competitions', '');
      v = v.replace(/[^\w]/g, '');

      v = 'bidayya.com/competitions/' + v;

      setCompetitionUrlS(v);
    } else setCompetitionUrlS('bidayya.com/competitions/');
  };

  const handleCoverImageChange = (newImage: File | null) => {
    if (newImage) {
      setCoverImage(newImage);
    } else {
      setCoverImage(null);
    }
  };

  const handleFilesChange: React.Dispatch<React.SetStateAction<File[]>> = (
    newFiles
  ) => {
    if (newFiles) {
      setFiles(newFiles);
    } else {
      setFiles([]);
    }
  };
  const handleMembersFileChange: React.Dispatch<
    React.SetStateAction<File[]>
  > = (newMails) => {
    if (newMails) {
      setMembersFile(newMails);
    } else {
      setMembersFile([]);
    }
  };
  const handleSubmit = () => {
    onSubmit();
  };
  const checkUrlExistsF = async () => {
    const url = competitionUrlS.replace('bidayya.com/competitions/', '');
    if (url.length > 0) {
      checkUrlExists({
        url,
      })
        .then((data) => {
          setUrlExists(data.exists);
        })
        .catch((error) => {});
    }
  };

  const tableStyle = {
    tr: 'bg-gray-100',
    td: 'border border-gray-300 px-4 py-2 text-left',
    table: 'min-w-fit table-auto border-collapse border border-gray-200',
  };

  return (
    <div className="mt-24 flex flex-col gap-3 transition-all duration-300 lg:w-1/2 lg:gap-4 xl:mx-8">
      <h1 className="text-start text-2xl font-bold sm:text-4xl">
        {edit ? 'Edit ' : 'Create '} Competition
      </h1>
      <h1 className="my-2 text-start text-lg font-bold sm:text-xl">
        Basic Setup
      </h1>
      <CompetitionFormField
        id="title"
        label="Title"
        value={title}
        onChange={(value) => setTitle(value)}
        placeholder="Enter a descriptive title"
        maxLength={50}
      />
      <CompetitionFormField
        id="companyName"
        label="Company Name"
        value={companyName}
        onChange={(value) => setCompanyName(value)}
        placeholder="Enter company name"
        maxLength={50}
      />
      <CompetitionFormField
        id="price"
        label="Price"
        type="number"
        value={price.toString()}
        onChange={(value) => setPrice(parseInt(value))}
        placeholder="Enter price"
      />
      <CompetitionFormField
        id="deadline"
        label="Deadline"
        type="date"
        value={submissionDeadline}
        onChange={(value) => {
          setSubmissionDeadline(value);
        }}
      />
      <CompetitionFormField
        id="description"
        label="Description"
        type="textArea"
        value={description}
        onChange={(value) => setDescription(value)}
        placeholder="Enter a description explaining what competitors will do."
        maxLength={225}
      />
      <div className="text-editor">
        <div className="text-start font-semibold">Overview</div>
        <ReactQuill
          theme="snow"
          value={overview}
          className="bg-gray-100"
          onChange={(value) => setOverview(value)}
          modules={{
            toolbar: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                {
                  align: [false, 'center', 'right', 'justify'],
                },
              ],
              [{ color: [] }, { background: [] }],
              ['link', 'code-block'],
              ['clean'],
            ],
          }}
          formats={[
            'header',
            'font',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'link',
            'image',
            'code-block',
            'color',
            'background',
            'align',
          ]}
          placeholder="Start typing here..."
        />
      </div>
      <div className="text-editor">
        <div className="text-start font-semibold">Rules</div>
        <ReactQuill
          theme="snow"
          value={rules}
          className="bg-gray-100"
          onChange={(value) => setRules(value)}
          modules={{
            toolbar: [
              [{ header: '1' }, { header: '2' }, { font: [] }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [
                {
                  align: [false, 'center', 'right', 'justify'],
                },
              ],
              [{ color: [] }, { background: [] }],
              ['link', 'code-block'],
              ['clean'],
            ],
          }}
          formats={[
            'header',
            'font',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'link',
            'image',
            'code-block',
            'color',
            'background',
            'align',
          ]}
          placeholder="Start typing here..."
        />
      </div>
      <CompetitionFormField
        id="url"
        label="URL"
        value={competitionUrlS}
        onChange={(value) => handleCompetitionUrlChange(value)}
        placeholder="bidayya.com/competitions/"
        onBlur={checkUrlExistsF}
      />
      {urlExists && (
        <div className="mb-1 text-start text-[13px] !text-red-500">
          URL already exists
        </div>
      )}

      <TagSelection tags={tags} setTags={(tags) => setTags(tags)} />

      <PrivacyAndAccess
        isPrivate={isPrivate}
        setIsPrivate={setIsPrivate}
        accessType={accessType}
        setAccessType={setAccessType}
      />

      {/* Upload Members Emails File */}
      {isPrivate && (
        <>
          <h2 className="my-2 text-start text-lg font-bold sm:text-xl">
            Upload Members Emails File
          </h2>
          <FileUploadField
            accept={{
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
              'application/vnd.ms-excel': ['.xls'],
            }}
            files={membersFile}
            setFiles={handleMembersFileChange}
            maxSize={10 * 1024 * 1024} // 10 MB per file
            icon={uploadIcon}
            pdfs={false}
            description="Supported formats: XLSX (.xlsx), XLS (.xls)"
          />
          <h3>Instructions</h3>
          <div className="flex w-full flex-wrap justify-between align-top">
            <ul className="mb-3 list-disc space-y-2 text-left">
              <li>File should conatin one column (for the mails)</li>
              <li>No column headers</li>
            </ul>
            <table className={tableStyle.table}>
              <tr className={tableStyle.tr}>
                <td className={tableStyle.td}>exmple1@mail.com</td>
                <td className={tableStyle.td}>..</td>
              </tr>
              <tr className={tableStyle.tr}>
                <td className={tableStyle.td}>exmple2@mail.com</td>
                <td className={tableStyle.td}>..</td>
              </tr>
              <tr className={tableStyle.tr}>
                <td className={tableStyle.td}>exmple3@mail.com</td>
                <td className={tableStyle.td}>..</td>
              </tr>
              <tr className={tableStyle.tr}>
                <td className={tableStyle.td}>..</td>
                <td className={tableStyle.td}>..</td>
              </tr>
            </table>
          </div>
        </>
      )}

      {/* Upload Cover Image Field */}
      <h2 className="my-2 text-start text-lg font-bold sm:text-xl">
        Upload Cover Image
      </h2>
      <ImageUploadField
        accept={{ 'image/*': ['.jpeg', '.png'] }}
        image={coverImage}
        setImage={handleCoverImageChange} // Set cover image
        maxSize={10 * 1024 * 1024} // 10 MB
        icon={uploadIcon}
      />

      {/* Upload Additional Files */}
      <h2 className="my-2 text-start text-lg font-bold sm:text-xl">
        Upload Additional Files
      </h2>
      <FileUploadField
        accept={{
          'image/*': [
            '.jpeg',
            '.png',
            '.gif',
            '.bmp',
            '.tiff',
            '.webp',
            '.x-icon',
          ],
          'application/pdf': ['.pdf'],
        }}
        multiple
        files={files}
        setFiles={handleFilesChange}
        maxSize={10 * 1024 * 1024} // 10 MB per file
        icon={uploadIcon}
        pdfs={true}
      />

      {/* Terms & Conditions */}
      <TermsAndConditions
        title={title}
        submissionDeadline={submissionDeadline}
        tags={tags}
        termsAccepted={termsAccepted}
        setTermsAccepted={setTermsAccepted}
      />

      {/* Submit Button */}
      <div className="my-6 max-w-[450px] text-start">
        <StyledButton
          label={
            buttonClicked
              ? edit
                ? 'Editing...'
                : 'Creating...'
              : edit
                ? 'Edit Competition'
                : 'Create Competition'
          }
          onClick={handleSubmit}
          className={` ${buttonClicked ? 'animate-pulse bg-gray-400' : ''}`}
          disabled={
            !title ||
            !companyName ||
            !price ||
            !coverImage ||
            !description ||
            !rules ||
            !overview ||
            !competitionUrlS.replace('bidayya.com/competitions/', '') ||
            !submissionDeadline ||
            tags.filter((t) => t.selected).length === 0 ||
            !termsAccepted ||
            urlExists ||
            buttonClicked
          }
        />
        {buttonClicked && (
          <div className="mt-2 animate-pulse text-center text-sm text-gray-600">
            Please wait while we {` ${edit ? 'edit' : 'create'} `} your
            competition.
          </div>
        )}
      </div>
    </div>
  );
};

export const CompetitionFormField: React.FC<{
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  type?: string;
  maxLength?: number;
  onBlur?: () => void;
}> = ({
  id,
  label,
  value,
  onChange,
  placeholder,
  type = 'text',
  maxLength,
  onBlur,
}) => (
  <div>
    <FormField
      id={id}
      label={label}
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => onBlur?.()}
      placeholder={placeholder}
      maxLength={maxLength}
      variant="primary"
    />
    {maxLength && (
      <div className="text-right text-gray-600">
        {value?.length}/{maxLength}
      </div>
    )}
  </div>
);

export const TagSelection: React.FC<{
  tags: Tag[];
  setTags: React.Dispatch<React.SetStateAction<Tag[]>>;
}> = ({ tags, setTags }) => (
  <>
    <h2 className="mb-1 block text-start text-sm font-medium text-black">
      Tag <span className="text-red-600">*</span>
    </h2>
    <ButtonGroup buttons={tags} setButtons={setTags} />
    <div className="my-1 text-start text-xs text-gray-600">
      Choose up to 10 tags.
    </div>
  </>
);

const PrivacyAndAccess: React.FC<{
  isPrivate: boolean;
  setIsPrivate: React.Dispatch<React.SetStateAction<boolean>>;
  accessType: CompetitionAccessType;
  setAccessType: React.Dispatch<React.SetStateAction<CompetitionAccessType>>;
}> = ({ isPrivate, setIsPrivate, accessType, setAccessType }) => (
  <>
    <h2 className="my-2 text-start text-lg font-bold sm:text-xl">
      Privacy, Access & Resources
    </h2>
    <h2 className="my-2 block text-start text-sm font-medium text-black">
      Visibility <span className="text-red-600">*</span>
    </h2>
    <div className="flex flex-row justify-between">
      <CheckboxWithLabel
        checked={!isPrivate}
        setChecked={() => setIsPrivate(false)}
        label="Public"
        description="Competition will be visible on Bidayya."
      />
      <CheckboxWithLabel
        checked={isPrivate}
        setChecked={() => setIsPrivate(true)}
        label="Private"
        description="Competition will not be visible on Bidayya."
      />
    </div>

    <h2 className="my-2 block text-start text-sm font-medium text-black">
      Who can join <span className="text-red-600">*</span>
    </h2>
    <div className="flex flex-col gap-4">
      <CheckboxWithLabel
        checked={accessType === CompetitionAccessType.ANYONE}
        setChecked={() => setAccessType(CompetitionAccessType.ANYONE)}
        label="Anyone"
        description="Anyone will be able to join via Bidayya or the competition URL."
      />
      <CheckboxWithLabel
        checked={accessType === CompetitionAccessType.ONLY_WITH_LINK}
        setChecked={() => setAccessType(CompetitionAccessType.ONLY_WITH_LINK)}
        label="Only people with a link"
        description="An invitation link will be available after you create this competition."
      />
    </div>
  </>
);

export const CheckboxWithLabel: React.FC<{
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  label: string;
  description: string;
}> = ({ checked, setChecked, label, description }) => (
  <div className="flex flex-col items-start">
    <Checkbox
      accepted={checked}
      setAccepted={setChecked}
      label={label}
      className={checked ? 'text-gray-700' : 'text-gray-400'}
    />
    <div className="mt-2 text-start text-xs text-gray-400">{description}</div>
  </div>
);

const TermsAndConditions: React.FC<{
  title: string;
  submissionDeadline: string;
  tags: Tag[];
  termsAccepted: boolean;
  setTermsAccepted: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ title, submissionDeadline, tags, termsAccepted, setTermsAccepted }) => {
  const [btnClicked, setBtnClicked] = useState<boolean>(false);
  const handleCheckboxChange = (newAccepted: boolean) => {
    if (newAccepted) {
      console.log('will check');
      setBtnClicked(true);
    } else {
      console.log('will cancel');
      setTermsAccepted(false);
    }
  };
  return (
    <div>
      <Dialog
        header="Terms and Conditions"
        visible={btnClicked}
        draggable={false}
        style={{
          width: '90vw',
          maxWidth: '600px',
          padding: '.2rem',
        }}
        onHide={() => setBtnClicked(false)}
      >
        <div>
          <h2 className="font-semibold">Host's Agreement</h2>
          <div className="scrollbar mt-2 max-h-[350px] overflow-y-scroll border border-light-blue p-2 text-start">
            <div className="mx-auto max-w-4xl rounded-lg p-4 shadow-lg">
              {/* Section 1: Competition Details */}
              <div className="mb-6">
                <h3 className="mb-2 text-lg font-bold">
                  1. Competition Details
                </h3>
                <ul className="space-y-2">
                  <li>
                    1.1. <strong>Competition Name: </strong>{' '}
                    {title || '_________________________'}
                  </li>
                  <li>
                    1.2. <strong>Competition Duration: </strong> From {'____'}{' '}
                    to{' '}
                    {(() => {
                      const date = new Date(submissionDeadline);
                      return isNaN(date.getTime())
                        ? '____' // Show placeholder if invalid
                        : date.toISOString().split('T')[0].replace(/-/g, '/');
                    })()}
                  </li>
                  <li>
                    1.3. <strong>Competition Description: </strong>{' '}
                    {
                      'The Host must provide detailed information about the competition, including rules, themes, submission guidelines, and evaluation criteria.'
                    }
                  </li>
                  <li>
                    1.4. <strong>Target Participants: </strong>{' '}
                    {tags
                      ?.reduce<string[]>((acc, tag) => {
                        if (tag.selected) {
                          acc.push(tag.tag_name);
                        }
                        return acc;
                      }, [])
                      .join(', ') || '___'}
                  </li>
                </ul>
              </div>

              {/* Section 2: Responsibilities of the Host */}
              <div className="mb-6">
                <h3 className="mb-2 text-lg font-bold">
                  2. Responsibilities of the Host
                </h3>
                <ul className="space-y-2">
                  <li>
                    2.1. <strong>Payment of Prizes: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        The Host is solely responsible for providing and paying
                        all monetary prizes specified in the competition
                        description.
                      </li>
                      <li>
                        Payment must be made directly to the winners within{' '}
                        <strong>60 days</strong> after the competition results
                        are announced.
                      </li>
                    </ul>
                  </li>
                  <li>
                    2.2. <strong>Medals and Recognition: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        The Host agrees to award virtual medals or certificates
                        of participation to{' '}
                        <strong>all competition participants</strong>,
                        regardless of whether they win a prize.
                      </li>
                    </ul>
                  </li>
                  <li>
                    2.3. <strong>Content Ownership: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        The Host will obtain full ownership rights to the
                        winning designs.
                      </li>
                      <li>
                        The Host may not use, reproduce, or distribute
                        non-winning designs without obtaining permission from
                        the respective participants.
                      </li>
                    </ul>
                  </li>
                  <li>
                    2.4. <strong>Evaluation and Winner Selection: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        The Host is responsible for evaluating all submissions
                        and determining the winners based on the criteria they
                        define.
                      </li>
                      <li>
                        The evaluation process must be fair, transparent, and in
                        accordance with the competition rules outlined on the
                        platform.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              {/* Section 3: Responsibilities of Bidayya */}
              <div className="mb-6">
                <h3 className="mb-2 text-lg font-bold">
                  3. Responsibilities of Bidayya
                </h3>
                <ul className="space-y-2">
                  <li>
                    3.1. <strong> Platform Services: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        Bidayya will provide a platform for the Host to
                        organize, promote, and manage the competition.
                      </li>
                      <li>
                        Bidayya will facilitate registration, submission
                        handling, and participant engagement.
                      </li>
                    </ul>
                  </li>
                  <li>
                    3.2. <strong>Promotion: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        Bidayya may promote the competition on its website,
                        social media channels, and marketing materials to
                        maximize participation.
                      </li>
                    </ul>
                  </li>
                  <li>
                    3.3. <strong>Participant Support: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        Bidayya will provide technical support to competition
                        participants regarding platform functionality and
                        submission processes.{' '}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              {/* Section 4: Payments and Fees */}
              <div className="mb-6">
                <h3 className="mb-2 text-lg font-bold">4. Payments and Fees</h3>
                <ul className="space-y-2">
                  <li>
                    4.1. <strong> Prize Amount: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        The total prize fund for the competition is
                        <ul className="list-disc space-y-2 pl-6">
                          <li>1st Place: $_____</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    4.2. <strong> Prize Payment Confirmation: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        The Host must provide proof of payment to the winners to
                        Bidayya within <strong>60 days </strong>
                        after the announcement of results.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              {/* Section 5: Intellectual Property */}
              <div className="mb-6">
                <h3 className="mb-2 text-lg font-bold">
                  5. Intellectual Property
                </h3>
                <ul className="space-y-2">
                  <li>
                    5.1. <strong>Ownership Transfer: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        The Host will acquire full ownership of the winning
                        designs, including rights to use, modify, and distribute
                        them.
                      </li>
                      <li>
                        Non-winning designs remain the property of the
                        participants unless otherwise agreed upon with the Host.
                      </li>
                    </ul>
                  </li>
                  <li>
                    5.2. <strong>Use of Submissions: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        The Host may request permission from participants to use
                        non-winning designs for promotional or commercial
                        purposes.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              {/* Section 6: Termination and Cancellation */}
              <div className="mb-6">
                <h3 className="mb-2 text-lg font-bold">
                  6. Termination and Cancellation
                </h3>
                <ul className="space-y-2">
                  <li>
                    6.1. <strong>Cancellation: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        The Host may cancel the competition with written notice
                        to Bidayya at least <strong>14 days</strong>
                        before the scheduled start date
                      </li>
                      <li>
                        If the competition is canceled after it has started, the
                        Host is still responsible for paying prizes to any
                        qualifying submissions received before cancellation.
                      </li>
                    </ul>
                  </li>
                  <li>
                    6.2. <strong>Termination: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        Bidayya reserves the right to terminate this Agreement
                        if the Host breaches any of its obligations, including
                        failure to pay prizes or provide participant
                        recognition.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              {/* Section 7: Liability and Indemnity */}
              <div className="mb-6">
                <h3 className="mb-2 text-lg font-bold">
                  7. Liability and Indemnity
                </h3>
                <ul className="space-y-2">
                  <li>
                    7.1. <strong>Host Liability: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        The Host agrees to indemnify and hold Bidayya harmless
                        from any claims, liabilities, or damages arising from
                        the Host’s failure to fulfill its obligations under this
                        Agreement.
                      </li>
                    </ul>
                  </li>
                  <li>
                    7.2. <strong> Platform Liability: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        Bidayya is not liable for any disputes between the Host
                        and participants, including prize disputes or claims
                        related to intellectual property.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

              {/* Section 8: General Terms */}
              <div>
                <h3 className="mb-2 text-lg font-bold">8. General Terms</h3>
                <ul className="space-y-2">
                  <li>
                    8.1. <strong>Confidentiality: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        Both parties agree to keep confidential any proprietary
                        information shared during the competition organization
                        process.
                      </li>
                    </ul>
                  </li>
                  <li>
                    8.2. <strong>Governing Law: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        This Agreement is governed by the laws of Lebanon.
                      </li>
                    </ul>
                  </li>
                  <li>
                    8.3. <strong>Entire Agreement: </strong>
                    <ul className="list-disc space-y-2 pl-6">
                      <li>
                        This Agreement constitutes the entire understanding
                        between the parties and supersedes any prior agreements
                        or understandings.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="mt-3 flex w-full items-center justify-end gap-3">
            <StyledButton
              type="button"
              className="text-xs font-bold"
              variant="secondary"
              label="Cancel"
              onClick={() => {
                setTermsAccepted(false);
                setBtnClicked(false);
              }}
            />
            <StyledButton
              type="button"
              className="bg-blue-gradient-right text-xs font-bold"
              variant="primary"
              label="I agree"
              onClick={() => {
                setTermsAccepted(true);
                setBtnClicked(false);
              }}
            />
          </div>
        </div>
      </Dialog>
      <div className="mt-4 flex max-w-[450px] flex-col items-start">
        <div className="my-2 text-start text-xs text-gray-700">
          Terms and agreement
        </div>
        <Checkbox
          accepted={termsAccepted}
          setAccepted={handleCheckboxChange}
          label="I confirm that this competition agrees with Bidayya's site terms and will not offer cash prizes without prior approval by the Bidayya Team."
          className="ml-4 text-gray-700"
        />
      </div>
    </div>
  );
};

export default CompetitionForm;
