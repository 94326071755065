import React from 'react';
import ImageComponent from '../common/ImageComponent';
import PDFs from '../common/PDFs';

import { Submission } from '../../types';

interface SubmissionDetailsProps {
  submission?: Submission;
  fromDashboard?: boolean;
}

const SubmissionDetails: React.FC<SubmissionDetailsProps> = ({
  submission,
  fromDashboard,
}) => {
  const imageFiles = submission?.files.filter(
    (file) => file.split('.').pop() !== 'pdf'
  );
  const pdfFiles = submission?.files.filter(
    (file) => file.split('.').pop() === 'pdf'
  );
  return (
    <div>
      <header
        className={`mb-4 ${!fromDashboard && 'mt-8'} flex flex-col items-start justify-between gap-3 lg:flex-row`}
      >
        <div className="flex flex-col gap-3 text-start lg:gap-4">
          {!fromDashboard && (
            <h1 className="text-2xl font-bold">Submission Details</h1>
          )}
          <h2 className="text-xl font-bold sm:text-4xl">{submission?.title}</h2>
        </div>
        <div className="flex flex-col gap-3 lg:gap-4">
          <ImageComponent
            className="h-40 w-64"
            src={submission?.cover_image || ''}
          />
        </div>
      </header>
      <hr />
      <div className="mt-3 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
        {imageFiles && imageFiles.length > 0 ? (
          imageFiles.map((file: any, index: number) => (
            <ImageComponent
              key={index}
              src={file}
              imageName={file || `Image ${index + 1}`}
              className="h-auto w-full"
            />
          ))
        ) : (
          <h3>No images available</h3>
        )}
      </div>
      <div className="mt-4">
        {pdfFiles && pdfFiles.length > 0 ? (
          <PDFs PDFs={pdfFiles} />
        ) : (
          <h3>No PDF files available</h3>
        )}
      </div>
      <p className="mt-5 text-start">{submission?.description}</p>
    </div>
  );
};

export default SubmissionDetails;
