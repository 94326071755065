import HireHero from './HireHero';

const LandingHire = () => {
  return (
    <div>
      <HireHero />
    </div>
  );
};

export default LandingHire;
