import { useEffect, useState } from 'react';
import { CompetitionDetails } from '../../types';
import { useParams } from 'react-router-dom';

const Rules = () => {
  const [competition, setCompetition] = useState<CompetitionDetails>();
  const { id } = useParams<{ id: string }>();
  const fetchCompetition = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/competitions/${id}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        setCompetition(data);
      } else {
        console.error('Error fetching competition:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching competition:', error);
    }
  };
  useEffect(() => {
    fetchCompetition();
  }, []);

  return (
    <div className="flex max-w-3xl flex-col gap-3">
      <h2 className="self-start text-2xl font-bold">Rules</h2>
      {competition?.rules && (
        <div
          className="prose text-start"
          dangerouslySetInnerHTML={{ __html: competition?.rules }}
        ></div>
      )}
    </div>
  );
};

export default Rules;
