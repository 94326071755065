type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

interface FetchApiOptions {
  method?: Method;
  headers?: HeadersInit;
  body?: any;
  queryParams?: Record<string, string | number | boolean>;
  formData?: FormData;
  // token?: string;
}

export async function fetchApi<T>(
  endpoint: string,
  {
    method = 'GET',
    headers = {},
    body,
    queryParams,
    formData,
    // token,
  }: FetchApiOptions = {}
): Promise<T> {
  // const url = new URL(endpoint, window.location.origin+'/api/v1');
  const url = new URL('/api/v1/' + endpoint, process.env.REACT_APP_API_URL);

  if (queryParams) {
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${String(value)}`)
      .join('&'); // Join with &

    url.search = queryString;
  }

  const defaultHeaders: HeadersInit = {
    // contentType: formData ? 'multipart/form-data' : 'application/json',
    // ...(token && { Authorization: `Bearer ${token}` }), // using cookies only for now
    ...headers,
  };

  // console.log(body.forEach((value: any, key: any) => console.log(key, value)));
  try {
    const response = await fetch(url, {
      method,
      credentials: 'include',
      headers: {
        ...defaultHeaders,
        ...(formData ? {} : { 'Content-Type': 'application/json' }),
      },
      body: body ? JSON.stringify(body) : formData ? formData : undefined,
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.message || `Error ${response.status}`);
    }

    return response.json();
  } catch (error: any) {
    console.error('API Error:', error.message);
    throw error;
  }
}
