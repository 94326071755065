import { useState } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './utils/CropImage';
import StyledButton from '../../../atoms/common/StyledButton';

const Crop = ({
  photoURL,
  setOpenCrop,
  setPhotoURL,
  setFile,
  originalPhoto,
}: {
  photoURL: string;
  setOpenCrop: (value: boolean) => void;
  setPhotoURL: (value: string) => void;
  setFile: (value: File) => void;
  originalPhoto: string;
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const cropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropImage = () => {
    getCroppedImg(photoURL, croppedAreaPixels, rotation)
      .then((res) => {
        // console.log(res)
        setFile(res.file);
        setPhotoURL(res.url);
        setOpenCrop(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="relative m-0 h-[40vh] w-auto">
        <div className="h-full">
          <Cropper
            image={photoURL}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={1}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            onCropChange={setCrop}
            onCropComplete={cropComplete}
          />
        </div>
      </div>

      <div className="mx-3 mt-10 flex flex-col">
        {/* Zoom Slider */}
        <div className="mt-0 h-fit w-full">
          <div className="w-full">
            <div className="flex items-center">
              <span className="mr-5 w-32 text-left">
                Zoom: {zoomPercent(zoom)}
              </span>
              <input
                type="range"
                min="1"
                max="3"
                step="0.1"
                value={zoom}
                className="w-full"
                onChange={(e) => setZoom(Number(e.target.value))}
              />
            </div>

            {/* Rotation Slider */}
            <div className="mt-3 flex items-center">
              <span className="mr-5 w-32 text-left">Rotation: {rotation}°</span>
              <input
                type="range"
                min="0"
                max="360"
                value={rotation}
                className="w-full"
                onChange={(e) => setRotation(Number(e.target.value))}
              />
            </div>
          </div>
        </div>

        <div className="m-0 mt-5 h-fit w-full">
          <div className="flex flex-wrap justify-around">
            <StyledButton
              label="Cancel"
              variant="secondary"
              onClick={() => {
                setPhotoURL(originalPhoto);
                setOpenCrop(false);
              }}
            />
            <StyledButton
              label="Crop"
              variant="primary"
              onClick={handleCropImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crop;

const zoomPercent = (value: number) => {
  return `${Math.round(value * 100)}%`;
};
